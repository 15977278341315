import { createSelector } from "reselect";
import { AppState } from "../store";
import { LEDGERS_NS, Filter } from "./types";
import {
  splitsByAccountIdFuncSelector,
  transactionByIdSelector,
  splitsByTransactionSelector
} from "../accounts/selectors";
import { nWayMergeSort } from "../../lib/algo/mergesort";
import { Transaction, Split } from "model/bookkeeping";

export const selectedAccountIdSelector = (state: AppState) =>
  state[LEDGERS_NS].selectedAccountIds;

export const filtersSelector = (state: AppState) => state[LEDGERS_NS].filters;

export const selectedSplitsSelector = createSelector(
  splitsByAccountIdFuncSelector,
  selectedAccountIdSelector,
  transactionByIdSelector,
  (splitsByAccountIdFunc, selectedAccounts, txById) => {
    if (selectedAccounts.length === 0) {
      return [];
    }
    if (selectedAccounts.length === 1) {
      return splitsByAccountIdFunc(selectedAccounts[0]);
    }

    return nWayMergeSort(
      selectedAccounts.map(id => splitsByAccountIdFunc(id)),
      sp => -txById[sp.transactionId].datetime.datetime.toMillis()
    );
  }
);

function searchTermMatch(haystack: string, needle: string): boolean {
  return haystack.toLocaleLowerCase().includes(needle.toLocaleLowerCase());
}

function splitFilter(
  split: Split,
  transaction: Transaction,
  allSplits: Split[],
  filter: Filter
) {
  if (filter.dateRange) {
    const time = transaction.datetime.datetime.toMillis();

    if (
      time < filter.dateRange.startTime.toMillis() ||
      time >= filter.dateRange.endTime.toMillis()
    ) {
      return false;
    }
  }
  if (
    filter.accountsInvolved &&
    filter.accountsInvolved.length > 0 &&
    filter.accountsInvolved.some(id =>
      allSplits.every(sp => sp.accountId !== id)
    )
  ) {
    return false;
  }
  if (
    filter.memo &&
    !searchTermMatch(split.memo || transaction.memo, filter.memo)
  ) {
    return false;
  }

  return true;
}

export const filteredSplitsSelector = createSelector(
  selectedSplitsSelector,
  transactionByIdSelector,
  splitsByTransactionSelector,
  filtersSelector,
  (splits, transactionById, splitsByTransaction, filters) => {
    if (!filters || filters.length === 0) {
      return splits;
    }
    return splits.filter(s =>
      filters.some(f =>
        splitFilter(
          s,
          transactionById[s.transactionId],
          splitsByTransaction[transactionById[s.transactionId].id],
          f
        )
      )
    );
  }
);

export const canGoBackSelector = (state: AppState) =>
  state[LEDGERS_NS].selectedAccountBackStack.length > 0;

export const canGoForwardSelector = (state: AppState) =>
  state[LEDGERS_NS].selectedAccountForwardStack.length > 0;

export const checkedSplitIdsSelector = (state: AppState) =>
  state[LEDGERS_NS].selectedSplitIds;

export const checkedSplitsSelector = createSelector(
  checkedSplitIdsSelector,
  filteredSplitsSelector,
  (selected, splits) => splits.filter(s => selected[s.id])
);
