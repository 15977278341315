import { createSelector } from "reselect";
import { AppState } from "../store";
import { IMPORTER_NS } from "./types";
import { ExternalAccount } from "../../model/bookkeeping";

export const importerNsSelector = (state: AppState) => state[IMPORTER_NS];

export const externalAccountDataListSelector = createSelector(
  importerNsSelector,
  state => Object.values(state.externalAccountsData)
);

export const externalAccountByIdSelector = createSelector(
  externalAccountDataListSelector,
  accountData =>
    accountData.reduce(
      (acc, data) => {
        acc[data.account.id] = data.account;
        return acc;
      },
      {} as { [key: string]: ExternalAccount }
    )
);

export const externalAccountDataSelector = (state: AppState) => (
  externalId: string
) => importerNsSelector(state).externalAccountsData[externalId];

export const entryDataByIdSelector = createSelector(
  importerNsSelector,
  state => state.entries
);

export const externalIdSetInAccountSelector = createSelector(
  entryDataByIdSelector,
  externalAccountDataSelector,
  (entryDataById, selector) => (externalId: string) => {
    const set = new Set<string>();
    for (const e of selector(externalId).entryIds) {
      set.add(entryDataById[e].entry.externalId);
    }
    return set;
  }
);

export const candidateTransactionsSelector = (state: AppState) =>
  importerNsSelector(state).candidateTransactions;

export const candidateTransactionByIdSelector = createSelector(
  candidateTransactionsSelector,
  transactions => (id: string) => transactions[id]
);

export const selectedEntryDataSelector = createSelector(
  importerNsSelector,
  entryDataByIdSelector,
  (state, entryById) =>
    Object.keys(state.selectedEntries).map(id => entryById[id])
);

export const csvSelector = createSelector(
  importerNsSelector,
  state => state.csv
);
