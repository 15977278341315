import { UUID } from "../../lib/core/uuid";
import { DateRangeData } from "lib/datetime/date_range";

export const LEDGERS_NS = "LEDGERS";

export interface Filter {
  dateRange?: DateRangeData;
  memo?: string;
  accountsInvolved?: UUID[];
}

export interface LedgersState {
  selectedAccountIds: UUID[];
  selectedAccountBackStack: UUID[][];
  selectedAccountForwardStack: UUID[][];
  // disjunction
  filters?: Filter[];

  selectedSplitIds: { [k: string]: boolean };
}
