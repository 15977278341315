import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  subaccountsMapSelector,
  accountByBasicAccountSelector,
  splitsByAccountIdFuncSelector,
  transactionByIdSelector
} from "data/accounts/selectors";
import { AppState } from "data/store";
import { BASIC_ACCOUNT_VALUES } from "data/accounts/tags";
import { negate, ScaledValue, sum, zero, format } from "model/scaled_value";
import { AccountValueDisplay } from "./common/account_value";
import { Account } from "model/bookkeeping";
import { Section } from "./common/section_wrapper";
import { DateRangePicker } from "../../common/date_range";
import {
  PredefinedDateRange,
  datetimeRange,
  DateRangeData
} from "../../../lib/datetime/date_range";

interface Props {
  subaccountsMap: ReturnType<typeof subaccountsMapSelector>;
  accountByBasicAccount: ReturnType<typeof accountByBasicAccountSelector>;
  splitsByAccountIdFunc: ReturnType<typeof splitsByAccountIdFuncSelector>;
  transactionById: ReturnType<typeof transactionByIdSelector>;
}

class IncomeStatementSection extends PureComponent<
  Props,
  { range: DateRangeData }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      range: datetimeRange(PredefinedDateRange.THIS_MONTH)
    };
  }

  calculateAccountValue(
    accounts: Account[],
    sign = (v: ScaledValue) => v
  ): [Account, ScaledValue][] {
    const { splitsByAccountIdFunc, transactionById } = this.props;

    const valueMap = new Map<Account, ScaledValue>();

    const startMillis = this.state.range.startTime.toMillis(),
      endMillis = this.state.range.endTime.toMillis();

    for (const acc of accounts) {
      const splits = splitsByAccountIdFunc(acc.id);
      let total = zero();
      for (const split of splits) {
        const splitMillis = transactionById[
          split.transactionId
        ].datetime.datetime.toMillis();
        if (splitMillis < endMillis) {
          if (splitMillis < startMillis) {
            break;
          } else {
            total = (total + split.valueScaled) as ScaledValue;
          }
        }
      }

      valueMap.set(acc, total);
    }

    return accounts.map(
      account =>
        [account, sign(valueMap.get(account) || zero())] as [
          Account,
          ScaledValue
        ]
    );
  }

  render() {
    const { subaccountsMap, accountByBasicAccount } = this.props;
    const incomeAccount = accountByBasicAccount(BASIC_ACCOUNT_VALUES.INCOME);
    const expensesAccount = accountByBasicAccount(
      BASIC_ACCOUNT_VALUES.EXPENSES
    );

    if (!incomeAccount || !expensesAccount) {
      return null;
    }

    const incomeBalances = this.calculateAccountValue(
      subaccountsMap(incomeAccount),
      negate
    ).filter(([_, valueScaled]) => valueScaled !== 0);
    const expensesBalances = this.calculateAccountValue(
      subaccountsMap(expensesAccount)
    ).filter(([_, valueScaled]) => valueScaled !== 0);

    const balance = sum(
      ...incomeBalances.map(([_, amount]) => amount),
      ...expensesBalances.map(([_, amount]) => negate(amount))
    );

    const allAccounts = [...incomeBalances, ...expensesBalances];

    return (
      <Section title="Income Statement">
        <h3>Net Income</h3>
        <h2>${format(balance)}</h2>
        <AccountValueDisplay values={allAccounts} sumChildren={true} />

        <DateRangePicker
          range={this.state.range}
          onChange={range => this.setState({ range })}
        />
      </Section>
    );
  }
}

export const ConnectedIncomeStatementSection = connect((state: AppState) => ({
  subaccountsMap: subaccountsMapSelector(state),
  accountByBasicAccount: accountByBasicAccountSelector(state),
  splitsByAccountIdFunc: splitsByAccountIdFuncSelector(state),
  transactionById: transactionByIdSelector(state)
}))(IncomeStatementSection);
