import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../data/store";
import { UUID } from "../../lib/core/uuid";
import { Account } from "../../model/bookkeeping";
import { accountsMapSelector } from "../../data/accounts/selectors";
import { AccountSelector, AccountDisplay } from "./account";

import buttonCss from "components/styles/button.module.css";
import css from "./multi_account_selector.module.css";

type Props = {
  selectedAccounts: UUID[];
  onChange(accounts: UUID[]): void;
  accounts: Map<UUID, Account>;
};

export class MultiAccountSelector extends PureComponent<Props, {}> {
  onAccountSelected = (id: string) => {
    this.props.onChange([...this.props.selectedAccounts, id]);
  };

  filter = (account: Account) =>
    !this.props.selectedAccounts.includes(account.id);

  remove = (id: UUID) => {
    this.props.onChange(this.props.selectedAccounts.filter(el => el !== id));
  };

  render() {
    return (
      <div className={css.multiAccountInput}>
        {this.props.selectedAccounts.map(id => (
          <span className={css.token}>
            <AccountDisplay accountName={this.props.accounts.get(id)!.name} />
            <button
              className={buttonCss.styleless}
              onClick={() => this.remove(id)}
            >
              X
            </button>
          </span>
        ))}
        <AccountSelector
          accounts={this.props.accounts}
          value={undefined}
          accountsFilter={this.filter}
          onChange={this.onAccountSelected}
          theme={{
            input: css.accountSelectInput,
            container: css.accountSelectContainer
          }}
        />
      </div>
    );
  }
}

export const ConnectedMultiAccountSelector = connect((state: AppState) => ({
  accounts: accountsMapSelector(state)
}))(MultiAccountSelector);
