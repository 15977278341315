import { Entry } from "./types";
import {
  ExternalAccount,
  ImportedEntry,
  Transaction,
  Split,
  Account
} from "../../model/bookkeeping";
import { ExternalAccountData } from "../../lib/ingestion/types";
import { UUID } from "../../lib/core/uuid";
import { ScaledValue } from "model/scaled_value";

export function importerFilesParsed(data: ExternalAccountData[]) {
  return {
    type: "IMPORTER/FILES_PARSED" as const,
    payload: data
  };
}

export function updateExternalAccounts(
  accounts: (Partial<ExternalAccount> & Pick<ExternalAccount, "externalId">)[]
) {
  return {
    type: "IMPORTER/UPDATE_EXTERNAL_ACCOUNTS" as const,
    payload: { accounts }
  };
}

export function setOtherAccount(
  entry: Entry,
  thisAccountId: UUID,
  otherAccountId: UUID
) {
  return {
    type: "IMPORTER/SET_OTHER_ACCOUNT" as const,
    payload: { entry, thisAccountId, otherAccountId }
  };
}

export function clearTransaction(entry: Entry) {
  return {
    type: "IMPORTER/CLEAR_TRANSACTION" as const,
    payload: { entry }
  };
}

export function setAutoCategorizePValue(entry: Entry, p: number | undefined) {
  return {
    type: "IMPORTER/SET_AUTO_CATEGORIZE_P" as const,
    payload: { entry, p }
  };
}

export function createTransaction(
  entries: ImportedEntry[],
  splits: Split[],
  transaction: Transaction
) {
  return {
    type: "IMPORTER/CREATE_TRANSACTION" as const,
    payload: { entries, splits, transaction }
  };
}

export function markEntriesImported(transactionIds: UUID[], splitIds: UUID[]) {
  return {
    type: "IMPORTER/MARK_ENTRIES_IMPORTED" as const,
    payload: {
      splitIds,
      transactionIds
    }
  };
}

export function importerReady() {
  return {
    type: "IMPORTER/READY" as const
  };
}

export function addAccountsAndEntries(
  externalAccounts: ExternalAccount[],
  importedEntries: ImportedEntry[]
) {
  return {
    type: "IMPORTER/ADD_ACCOUNTS_ENTRIES" as const,
    payload: {
      externalAccounts,
      importedEntries
    }
  };
}

export function addBalancingTransaction(
  externalAccount: ExternalAccount,
  account: Account,
  openingBalance: ScaledValue
) {
  return {
    type: "IMPORTER/ADD_BALANCING_TRANSACTION" as const,
    payload: {
      externalAccount,
      account,
      openingBalance
    }
  };
}

export function setEntrySelectedStatus(id: UUID, status: boolean) {
  return {
    type: "IMPORTER/SET_ENTRY_SELECTED" as const,
    payload: {
      id,
      status
    }
  };
}

export function unselectAll(externalAccount: ExternalAccount) {
  return {
    type: "IMPORTER/UNSELECT_ALL" as const,
    payload: { externalAccount }
  };
}

export function setShowImportedEntries(show: boolean) {
  return {
    type: "IMPORTER/SET_SHOW_IMPORTED_ENTRIES" as const,
    payload: show
  };
}

export function importCSV(data: string[][]) {
  return {
    type: "IMPORTER/IMPORT_CSV" as const,
    payload: { data }
  };
}

export function clearCSV() {
  return {
    type: "IMPORTER/CLEAR_CSV" as const
  };
}

export type ImporterAction =
  | ReturnType<typeof importerFilesParsed>
  | ReturnType<typeof updateExternalAccounts>
  | ReturnType<typeof setOtherAccount>
  | ReturnType<typeof clearTransaction>
  | ReturnType<typeof setAutoCategorizePValue>
  | ReturnType<typeof markEntriesImported>
  | ReturnType<typeof importerReady>
  | ReturnType<typeof addBalancingTransaction>
  | ReturnType<typeof addAccountsAndEntries>
  | ReturnType<typeof setEntrySelectedStatus>
  | ReturnType<typeof createTransaction>
  | ReturnType<typeof unselectAll>
  | ReturnType<typeof setShowImportedEntries>
  | ReturnType<typeof importCSV>
  | ReturnType<typeof clearCSV>;
