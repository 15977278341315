import { LEDGERS_NS, LedgersState } from "./types";
import { LedgersAction } from "./action_creators";
import { LEDGERS_DEFAULT_STATE } from "./default_state";
import { AppState } from "../store";
import { reducerInNs } from "../utils";
import produce from "immer";

export const ledgersReducer = reducerInNs(
  LEDGERS_NS,
  (
    state: LedgersState = LEDGERS_DEFAULT_STATE,
    action: LedgersAction,
    fullState: AppState
  ) => {
    switch (action.type) {
      case "LEDGERS/SET_SELECTED_ACCOUNT": {
        return {
          ...state,
          selectedAccountIds: action.payload.accountIds,
          selectedAccountForwardStack: [],
          selectedAccountBackStack: [
            state.selectedAccountIds,
            ...state.selectedAccountBackStack
          ]
        };
      }
      case "LEDGERS/SELECTED_ACCOUNTS_BACK": {
        if (state.selectedAccountBackStack.length > 0) {
          const [accounts, ...newBackStack] = state.selectedAccountBackStack;

          return {
            ...state,
            selectedAccountIds: accounts,
            selectedAccountBackStack: newBackStack,
            selectedAccountForwardStack: [
              state.selectedAccountIds,
              ...state.selectedAccountForwardStack
            ]
          };
        }
        return state;
      }
      case "LEDGERS/SELECTED_ACCOUNTS_FORWARD": {
        if (state.selectedAccountForwardStack.length > 0) {
          const [
            accounts,
            ...newForwardStack
          ] = state.selectedAccountForwardStack;

          return {
            ...state,
            selectedAccountIds: accounts,
            selectedAccountForwardStack: newForwardStack,
            selectedAccountBackStack: [
              state.selectedAccountIds,
              ...state.selectedAccountBackStack
            ]
          };
        }
        return state;
      }
      case "LEDGERS/SET_FILTER": {
        const { filter, index } = action.payload;
        return produce(state, draftState => {
          if (!draftState.filters) {
            draftState.filters = [];
          }
          while (index >= draftState.filters.length) {
            draftState.filters.push({});
          }
          Object.assign(draftState.filters[index], filter);
        });
      }
      case "LEDGERS/CLEAR_FILTER": {
        return {
          ...state,
          filters: []
        };
      }
      case "LEDGERS/REMOVE_FILTER": {
        const { index } = action.payload;
        return produce(state, draftState => {
          if (draftState.filters) {
            draftState.filters.splice(index, 1);
          }
        });
      }
      case "LEDGERS/SET_FILTER_DATE_RANGE": {
        const dateRange = {
          range: "_CUSTOM" as const,
          startTime: action.payload.start,
          endTime: action.payload.end
        };
        if (!state.filters || state.filters.length === 0) {
          return {
            ...state,
            filters: [
              {
                dateRange
              }
            ]
          };
        } else {
          return {
            ...state,
            filters: state.filters.map(filter => ({ ...filter, dateRange }))
          };
        }
      }
      case "LEDGERS/CLEAR_SPLIT_SELECTED": {
        return {
          ...state,
          selectedSplitIds: {}
        };
      }
      case "LEDGERS/SET_SPLIT_SELECTED": {
        return {
          ...state,
          selectedSplitIds: {
            ...state.selectedSplitIds,
            ...action.payload.selectedSplits
          }
        };
      }
    }
    return state;
  }
);
