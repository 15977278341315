import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState, Dispatch } from "../../data/store";
import { oobeNsSelector } from "../../data/oobe/selectors";
import { Scenario } from "../../static_data/basic_accounts";
import { finishSetupAccounts } from "../../data/oobe/actions";
import { Modal } from "components/common/modal";

const SCENARIOS: [string, Scenario][] = [
  ["I own investments (stocks, bonds)", Scenario.INVESTMENT],
  ["I rent my residence", Scenario.RENT_RESIDENCE],
  ["I own my residence", Scenario.OWN_RESIDENCE],
  ["I have savings", Scenario.DEPOSIT],
  ["I drive an automobile", Scenario.AUTO],
  ["I have pets", Scenario.PET],
  ["I have children", Scenario.CHILD]
];

type OobeSetupAccountsState = {
  scenarios: { [k in Scenario]?: boolean };
};

class OobeSetupAccounts extends PureComponent<
  {
    dispatch: Dispatch;
  },
  OobeSetupAccountsState
> {
  state: OobeSetupAccountsState = { scenarios: {} };

  private onDone = () => {
    this.props.dispatch(
      finishSetupAccounts(
        new Set<Scenario>(
          (Object.keys(this.state.scenarios) as Scenario[]).filter(
            k => !!this.state.scenarios[k]
          )
        )
      )
    );
  };

  render() {
    return (
      <Modal isOpen={true}>
        <h1>Setup Accounts</h1>
        <ul>
          {SCENARIOS.map(([name, scenario]) => (
            <li key={scenario}>
              <input
                id={`scenario-${scenario}`}
                type="checkbox"
                checked={!!this.state.scenarios[scenario]}
                onChange={e =>
                  this.setState({
                    scenarios: {
                      ...this.state.scenarios,
                      [scenario]: e.currentTarget.checked
                    }
                  })
                }
              />
              <label htmlFor={`scenario-${scenario}`}>{name}</label>
            </li>
          ))}
        </ul>
        <button onClick={this.onDone}>Done</button>
      </Modal>
    );
  }
}

export const ConnectedOobeSetupAccounts = connect()(OobeSetupAccounts);

export class Oobe extends PureComponent<{ step?: number }, {}> {
  render() {
    if (!this.props.step) {
      return null;
    }
    return <ConnectedOobeSetupAccounts />;
  }
}

export const ConnectedOobe = connect((state: AppState) => ({
  step: oobeNsSelector(state).step
}))(Oobe);
