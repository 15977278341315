import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  subaccountsMapSelector,
  splitsByAccountIdFuncSelector,
  transactionByIdSelector,
  accountsByTagValueSelector
} from "data/accounts/selectors";
import { AppState } from "data/store";
import { CASH_ACCOUNT } from "data/accounts/tags";
import { sum, format } from "model/scaled_value";
import { Split } from "model/bookkeeping";
import { Section } from "./common/section_wrapper";
import { DateRangePicker } from "../../common/date_range";
import {
  PredefinedDateRange,
  datetimeRange,
  DateRangeData
} from "../../../lib/datetime/date_range";
import flatten from "lodash/flatten";

interface Props {
  accountsByTagValue: ReturnType<typeof accountsByTagValueSelector>;
  subaccountsMap: ReturnType<typeof subaccountsMapSelector>;
  splitsByAccountIdFunc: ReturnType<typeof splitsByAccountIdFuncSelector>;
  transactionById: ReturnType<typeof transactionByIdSelector>;
}

class CashFlowStatementSection extends PureComponent<
  Props,
  { range: DateRangeData }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      range: datetimeRange(PredefinedDateRange.THIS_MONTH)
    };
  }

  render() {
    const {
      splitsByAccountIdFunc,
      accountsByTagValue,
      transactionById,
      subaccountsMap
    } = this.props;
    const cashAccounts = new Set(
      flatten(
        accountsByTagValue(CASH_ACCOUNT, v => !!v).map(account =>
          subaccountsMap(account)
        )
      )
    );

    const allSplits: Split[] = [];
    cashAccounts.forEach(account =>
      allSplits.push(
        ...splitsByAccountIdFunc(account.id).filter(split => {
          const time = transactionById[
            split.transactionId
          ].datetime.datetime.toMillis();
          return (
            time >= this.state.range.startTime.toMillis() &&
            time < this.state.range.endTime.toMillis()
          );
        })
      )
    );

    const netChange = sum(...allSplits.map(s => s.valueScaled));

    return (
      <Section title="Cash Flow Statement">
        <h3>Net Change</h3>
        <h2>${format(netChange)}</h2>

        <DateRangePicker
          range={this.state.range}
          onChange={range => this.setState({ range })}
        />
      </Section>
    );
  }
}

export const ConnectedCashFlowStatementSection = connect((state: AppState) => ({
  accountsByTagValue: accountsByTagValueSelector(state),
  subaccountsMap: subaccountsMapSelector(state),
  splitsByAccountIdFunc: splitsByAccountIdFuncSelector(state),
  transactionById: transactionByIdSelector(state)
}))(CashFlowStatementSection);
