import { Split, Transaction, Account } from "../../model/bookkeeping";

export const ACCOUNTS_NS = "ACCOUNTS";

interface AccountData {
  account: Account;
}

export interface AccountsState {
  accountData: { [id: string]: AccountData };
  transactions: { [id: string]: Transaction };
  splits: { [id: string]: Split };
  ready?: boolean;
}

export type TransactionMutations = {
  dirtyTransactions: {
    [id: string]: Partial<Transaction>;
  };
  dirtySplits: {
    [id: string]: Partial<Split>;
  };
  newTransactions: Transaction[];
  newSplits: Split[];
};
