import { Account, Transaction, Split } from "../../model/bookkeeping";
import { UUID } from "../../lib/core/uuid";
import { TransactionMutations } from "./types";

export function addTransactionsAndSplits(
  transactions: Transaction[],
  splits: Split[]
) {
  return {
    type: "ACCOUNTS/ADD_TRANSACTIONS_SPLITS" as const,
    payload: { transactions, splits }
  };
}

export function addAccounts(accounts: Account[]) {
  return {
    type: "ACCOUNTS/ADD_ACCOUNTS" as const,
    payload: accounts
  };
}

export function setAccountTag(accountId: UUID, tag: string, value: string) {
  return {
    type: "ACCOUNTS/SET_ACCOUNT_TAG" as const,
    payload: { accountId, tag, value }
  };
}

export function updateTransactionsAndSplits(mutations: TransactionMutations) {
  return {
    type: "ACCOUNTS/UPDATE_TRANSACTIONS_AND_SPLITS" as const,
    payload: { mutations }
  };
}

export function accountsReady() {
  return {
    type: "ACCOUNTS/READY" as const
  };
}

export type AccountsAction =
  | ReturnType<typeof addAccounts>
  | ReturnType<typeof addTransactionsAndSplits>
  | ReturnType<typeof updateTransactionsAndSplits>
  | ReturnType<typeof setAccountTag>
  | ReturnType<typeof accountsReady>;
