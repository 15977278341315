import { EntryCategory } from "static_data/categories";
import { Service, Route } from "./lib";
import {
  Transaction,
  Split,
  ImportedEntry,
  ExternalAccount,
  Account
} from "model/bookkeeping";
import { SerializeDateTime } from "persistence/serializer";
import { UUID } from "lib/core/uuid";
import { BackupDataSchema } from "persistence/backup";

export const service = new Service(
  process.env.NODE_ENV === "production"
    ? "https://bm-api.heavenfox.org"
    : "http://localhost:5000"
);

export const autoCategorizeRoute = new Route<
  string[],
  {
    category: EntryCategory;
    p: number;
  }[]
>("/autocategorize");

export const addTransactionsRoute = new Route<
  {
    transactions: SerializeDateTime<Transaction>[];
    splits: SerializeDateTime<Split>[];
  },
  void
>("/persist/add_transactions");

export const addAccountsRoute = new Route<{ accounts: Account[] }, void>(
  "/persist/add_accounts"
);

export const getAccountDataRoute = new Route<
  {},
  {
    accounts: Account[];
    transactions: SerializeDateTime<Transaction>[];
    splits: SerializeDateTime<Split>[];
  }
>("/persist/get_account_data");

export const getImporterDataRoute = new Route<
  {},
  {
    importedEntries: SerializeDateTime<ImportedEntry>[];
    externalAccounts: ExternalAccount[];
  }
>("/persist/get_importer_data");

export const updateImportedEntrySplitIdsRoute = new Route<
  {
    [key: string]: string;
  },
  void
>("/persist/update_imported_entry_split_ids");

export const addExternalAccountsAndEntriesRoute = new Route<
  {
    externalAccount: ExternalAccount | undefined;
    entries: SerializeDateTime<ImportedEntry>[];
  },
  string[]
>("/persist/add_external_accounts_and_entries");

export const updateExternalAccountRoute = new Route<
  {
    externalAccount: Partial<ExternalAccount> & Pick<ExternalAccount, "id">;
  },
  void
>("/persist/update_external_account");

export const updateAccountTagsRoute = new Route<
  {
    accountId: UUID;
    tags: { [tag: string]: string };
  },
  void
>("/persist/update_account_tags");

export const updateTransactionsAndSplitsRoute = new Route<
  {
    dirtyTransactions: {
      [id: string]: Partial<SerializeDateTime<Transaction>>;
    };
    dirtySplits: {
      [id: string]: Partial<SerializeDateTime<Split>>;
    };
    newTransactions: SerializeDateTime<Transaction>[];
    newSplits: SerializeDateTime<Split>[];
  },
  void
>("/persist/update_transactions_and_splits");

export const backupRoute = new Route<{}, BackupDataSchema>("/persist/backup");
export const restoreRoute = new Route<BackupDataSchema, void>(
  "/persist/restore"
);
