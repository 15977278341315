import { DateTime, DurationUnit } from "luxon";
export enum PredefinedDateRange {
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_YEAR = "LAST_YEAR"
}

export interface DateRangeData {
  range: PredefinedDateRange | "_CUSTOM";
  startTime: DateTime;
  endTime: DateTime;
}

export function defaultDateRange() {
  return datetimeRange(PredefinedDateRange.THIS_MONTH);
}

export function datetimeRange(range: PredefinedDateRange): DateRangeData {
  const rangeForThis = (unit: DurationUnit) => ({
    range,
    startTime: DateTime.local().startOf(unit),
    endTime: DateTime.local()
  });
  const rangeForLast = (unit: DurationUnit) => {
    const end = DateTime.local().startOf(unit);
    return { range, startTime: end.minus(1).startOf(unit), endTime: end };
  };
  switch (range) {
    case PredefinedDateRange.THIS_WEEK:
      return rangeForThis("week");
    case PredefinedDateRange.LAST_WEEK:
      return rangeForLast("week");
    case PredefinedDateRange.THIS_MONTH:
      return rangeForThis("month");
    case PredefinedDateRange.LAST_MONTH:
      return rangeForLast("month");
    case PredefinedDateRange.THIS_YEAR:
      return rangeForThis("year");
    case PredefinedDateRange.LAST_YEAR:
      return rangeForLast("year");
  }
}
