export const PATH_SEPARATOR = ":";

export function breakAccountName(name: string): string[] {
  return name.split(PATH_SEPARATOR);
}

export function joinAccountName(path: string[]): string {
  return path.join(PATH_SEPARATOR);
}

export function isSubaccount(parent: string, child: string) {
  return child.startsWith(parent + PATH_SEPARATOR);
}

export function treeify<R, T>(
  items: T[],
  getPath: (v: T) => string[],
  getTreeValue: (v: R) => string,
  getChildren: (t: R) => R[],
  createTree: (i: T, soFar: string[]) => R,
  handleLeaf: (i: T, r: R) => void
): R[] {
  function addAccountToTree(
    forest: R[],
    account: T,
    path: string[],
    pathIndex: number
  ) {
    let tree;
    for (const candidateTree of forest) {
      if (getTreeValue(candidateTree) === path[pathIndex]) {
        tree = candidateTree;
        break;
      }
    }
    if (!tree) {
      tree = createTree(account, path.slice(0, pathIndex + 1));
      forest.push(tree);
    }

    if (pathIndex === path.length - 1) {
      handleLeaf(account, tree);
    } else {
      addAccountToTree(getChildren(tree), account, path, pathIndex + 1);
    }
  }
  const result: R[] = [];
  for (const item of items) {
    const path = getPath(item);
    addAccountToTree(result, item, path, 0);
  }
  return result;
}
