import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ConnectedSplitsDisplay } from "./ledger";

class LedgerDisplay extends PureComponent<{}, {}> {
  render() {
    return (
      <div>
        <ConnectedSplitsDisplay />
      </div>
    );
  }
}

export const ConnectedLedgerDisplay = connect()(LedgerDisplay);
