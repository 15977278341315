import { UUID } from "../lib/core/uuid";
import { ScaledValue } from "./scaled_value";
import { ExternalAccountProvider } from "../lib/ingestion/types";
import { BMDateTime } from "../lib/datetime/types";

export interface Account {
  id: UUID;
  name: string;
  tags: { [key: string]: string };
}

export interface Split {
  id: UUID;
  transactionId: UUID;
  accountId: UUID;
  valueScaled: ScaledValue;
  datetime: BMDateTime;
  memo?: string;
  tags: { [key: string]: string };
}

export interface Transaction {
  id: UUID;
  memo: string;
  datetime: BMDateTime;
  tags: { [key: string]: string };
}

export interface ImportedEntry {
  id: UUID;
  externalId: string;
  externalAccountId: UUID;
  valueScaled: ScaledValue;
  memo: string;
  datetime: BMDateTime;
  splitId?: string;
  raw: object;
}

export interface ExternalAccount {
  id: UUID;
  externalId: string;
  name: string;
  type?: ExternalAccountType;
  provider: ExternalAccountProvider;
  balanceScaled?: ScaledValue;
}

export enum ExternalAccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
  MONEY_MARKET = "MONEY_MARKET",
  CD = "CD",
  LINE_OF_CREDIT = "LINE_OF_CREDIT",
  CREDIT_CARD = "CREDIT_CARD"
}
