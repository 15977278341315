export type ScaledValue = number & { __brand: "ScaledValue" };

const SCALE_FACTOR = 10000;
const DECIMAL_PLACES = Math.round(Math.log10(SCALE_FACTOR));

export function scaleValue(value: number | string): ScaledValue {
  return Math.round(+value * SCALE_FACTOR) as ScaledValue;
}

export function unscaleValue(value: ScaledValue): number {
  return value / SCALE_FACTOR;
}

export function sum(...values: ScaledValue[]): ScaledValue {
  return values.reduce((acc, v) => (acc + v) as ScaledValue, 0 as ScaledValue);
}

export function negate(value: ScaledValue): ScaledValue {
  return -value as ScaledValue;
}

export function zero(): ScaledValue {
  return 0 as ScaledValue;
}

export function abs(value: ScaledValue): ScaledValue {
  if (value >= 0) {
    return value;
  }
  return -value as ScaledValue;
}

export function format(value: ScaledValue): string {
  return unscaleValue(value).toFixed(2);
}

export function formatDebitCredit(
  value: ScaledValue
): [string, undefined] | [undefined, string] {
  if (value > 0) {
    return [format(value), undefined];
  } else {
    return [undefined, format(negate(value))];
  }
}

export function fromString(num: string): ScaledValue {
  const str = num.replace(/[^0-9.]/g, "");
  const [integer, decimal] = str.split(".");
  return fromParts(integer, decimal);
}

export function fromParts(
  integer: string,
  decimal: string | undefined
): ScaledValue {
  return (parseInt(integer, 10) * SCALE_FACTOR +
    (decimal
      ? parseInt(decimal.padEnd(DECIMAL_PLACES, "0"), 10)
      : 0)) as ScaledValue;
}
