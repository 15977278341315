import cx from "classnames";
import React, { PureComponent } from "react";
import { Transaction, Split } from "model/bookkeeping";
import groupBy from "lodash/groupBy";
import { ValueScaledInput } from "components/common/value_scaled_input";
import { ConnectedAccountSelector } from "components/common/account";
import { UUID, newUuid } from "lib/core/uuid";
import { sum, negate, ScaledValue } from "model/scaled_value";
import { BMDateTime } from "lib/datetime/types";

import css from "./transactions_editor.module.css";
import { DateTime } from "luxon";
import { today } from "lib/datetime/date";
import { TransactionMutations } from "data/accounts/types";
import flatten from "lodash/flatten";
import { formValidTransactions } from "lib/accounts/validator";

interface DateEditorProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "value" | "onChange"
  > {
  value: BMDateTime;
  onChange(date: BMDateTime): void;
}

export const DateEditor: React.FC<DateEditorProps> = props => {
  return (
    <input
      type={props.value.hasTime ? "datetime-local" : "date"}
      value={
        props.value.hasTime
          ? props.value.datetime.toISO({
              suppressMilliseconds: true,
              suppressSeconds: true,
              includeOffset: false
            })
          : props.value.datetime.toISODate()
      }
      onChange={e =>
        props.onChange({
          datetime: DateTime.fromISO(e.currentTarget.value).setZone(
            props.value.datetime.zone,
            { keepLocalTime: true }
          ),
          hasTime: props.value.hasTime
        })
      }
    />
  );
};

type Prop = {
  transactions: Transaction[];
  splits: Split[];
  onSave(mods: TransactionMutations): void;
};

type State = TransactionMutations & {
  hoverTransactionId?: UUID | "_NEW";
};

export class TransactionsEditor extends PureComponent<Prop, State> {
  state: State = {
    dirtyTransactions: {},
    dirtySplits: {},
    newTransactions: [],
    newSplits: []
  };

  componentDidUpdate(prevProps: Prop) {
    if (
      this.props.splits !== prevProps.splits ||
      this.props.transactions !== prevProps.transactions
    ) {
      this.setState({
        dirtyTransactions: {},
        dirtySplits: {},
        newSplits: [],
        newTransactions: []
      });
    }
  }

  updateSplit = (splitId: UUID, updated: Partial<Split>) => {
    this.setState({
      dirtySplits: {
        ...this.state.dirtySplits,
        [splitId]: {
          ...this.state.dirtySplits[splitId],
          ...updated
        }
      }
    });
  };

  updateTransaction = (txId: UUID, updated: Partial<Transaction>) => {
    this.setState({
      dirtyTransactions: {
        ...this.state.dirtyTransactions,
        [txId]: {
          ...this.state.dirtyTransactions[txId],
          ...updated
        }
      }
    });
  };

  newSplit = (
    id: UUID,
    accountId: UUID,
    transactionId: UUID,
    valueScaled: ScaledValue,
    datetime: BMDateTime
  ) => {
    this.setState({
      newSplits: [
        ...this.state.newSplits,
        {
          id,
          accountId,
          transactionId,
          valueScaled,
          datetime,
          tags: {}
        }
      ]
    });
  };

  addTransaction = () => {
    const id = newUuid();
    this.setState({
      newTransactions: [
        ...this.state.newTransactions,
        {
          id,
          memo: "New Transaction",
          datetime: today(),
          tags: {}
        }
      ]
    });
    return id;
  };

  render() {
    const allTransactions = [
      ...this.props.transactions,
      ...this.state.newTransactions
    ].map(tx =>
      this.state.dirtyTransactions[tx.id]
        ? { ...tx, ...this.state.dirtyTransactions[tx.id] }
        : tx
    );

    const splits = [...this.props.splits, ...this.state.newSplits].map(split =>
      this.state.dirtySplits[split.id]
        ? { ...split, ...this.state.dirtySplits[split.id] }
        : split
    );

    const splitsByTransaction = groupBy(splits, split => split.transactionId);

    const disabled = !formValidTransactions(
      flatten(Object.values(splitsByTransaction))
    );

    return (
      <div>
        {allTransactions.map(tx => {
          const splits = splitsByTransaction[tx.id];
          const subtotal = sum(...splits.map(s => s.valueScaled));

          const newSplitId = newUuid();

          return (
            <div
              className={cx({
                [css.hoverTransaction]: this.state.hoverTransactionId === tx.id
              })}
              onDragOver={e => {
                e.preventDefault();
                this.setState({ hoverTransactionId: tx.id });
              }}
              onDragLeave={() =>
                this.setState({ hoverTransactionId: undefined })
              }
              onDrop={e => {
                e.preventDefault();
                this.setState({ hoverTransactionId: undefined });
                const splitId = e.dataTransfer.getData(
                  "text/x-ducount-split-id"
                );
                if (splitId) {
                  this.updateSplit(splitId, {
                    transactionId: tx.id
                  });
                }
              }}
            >
              <input
                type="text"
                value={tx.memo}
                onChange={e =>
                  this.updateTransaction(tx.id, { memo: e.currentTarget.value })
                }
              />
              <DateEditor
                value={tx.datetime}
                onChange={datetime =>
                  this.updateTransaction(tx.id, {
                    datetime
                  })
                }
              />

              <ul>
                {splits
                  .map(split => (
                    <li
                      key={split.id}
                      draggable={true}
                      onDragStart={e => {
                        e.dataTransfer.setData(
                          "text/x-ducount-split-id",
                          split.id
                        );
                        e.dataTransfer.dropEffect = "move";
                      }}
                    >
                      <div>
                        <ConnectedAccountSelector
                          value={split.accountId}
                          onChange={accountId =>
                            this.updateSplit(split.id, { accountId })
                          }
                        />
                        <ValueScaledInput
                          value={split.valueScaled}
                          onChange={valueScaled =>
                            this.updateSplit(split.id, { valueScaled })
                          }
                        />
                      </div>
                      <div>
                        <DateEditor
                          value={split.datetime}
                          onChange={datetime =>
                            this.updateSplit(split.id, {
                              datetime
                            })
                          }
                        />
                        <button
                          onClick={() =>
                            this.updateSplit(split.id, {
                              datetime: tx.datetime
                            })
                          }
                        >
                          Copy from Transaction
                        </button>
                      </div>
                    </li>
                  ))
                  .concat(
                    subtotal === 0
                      ? []
                      : [
                          <li key={newSplitId}>
                            <ConnectedAccountSelector
                              onChange={accountId =>
                                this.newSplit(
                                  newSplitId,
                                  accountId,
                                  tx.id,
                                  negate(subtotal),
                                  tx.datetime
                                )
                              }
                            />
                            <ValueScaledInput value={negate(subtotal)} />
                          </li>
                        ]
                  )}
              </ul>
            </div>
          );
        })}
        <div
          className={cx(css.addTransactionBox, {
            [css.hoverTransaction]: this.state.hoverTransactionId === "_NEW"
          })}
          onDragOver={e => {
            e.preventDefault();
            this.setState({ hoverTransactionId: "_NEW" });
          }}
          onDragLeave={() => this.setState({ hoverTransactionId: undefined })}
          onDrop={e => {
            e.preventDefault();
            this.setState({ hoverTransactionId: undefined });
            const splitId = e.dataTransfer.getData("text/x-ducount-split-id");
            if (splitId) {
              const newTxId = this.addTransaction();
              this.updateSplit(splitId, {
                transactionId: newTxId
              });
            }
          }}
        >
          +
        </div>

        <button
          disabled={disabled}
          onClick={() =>
            this.props.onSave({
              newSplits: this.state.newSplits,
              newTransactions: this.state.newTransactions,
              dirtySplits: this.state.dirtySplits,
              dirtyTransactions: this.state.dirtyTransactions
            })
          }
        >
          Save
        </button>
      </div>
    );
  }
}
