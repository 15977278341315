import React, { useState } from "react";
import cx from "classnames";
import { ConnectedAccountsList } from "./accounts_list";
import css from "./main.module.css";
import { ConnectedLedgerDisplay } from "./display";
import { ConnectedChart } from "./chart";
import { SegmentedButton } from "components/common/segmented_button";
import { ConnectedAccountsSettings } from "./accounts_settings";
import { ConnectedTopBar } from "./top_bar";
import pageCss from "components/styles/page.module.css";

enum Tabs {
  LEDGER = "LEDGER",
  GRAPH = "GRAPH",
  SETTINGS = "SETTINGS"
}

export const LedgersMain: React.FC<{}> = () => {
  const [tab, setTab] = useState(Tabs.LEDGER);
  return (
    <div className={pageCss.pageWrapper}>
      <div className={pageCss.pageTitleWrapper}>
        <h1 className={pageCss.pageTitle}>Accounts</h1>
        <div className={pageCss.pageTitleNav}>
          <SegmentedButton
            buttons={[
              { label: "Ledger", value: Tabs.LEDGER },
              { label: "Graph", value: Tabs.GRAPH },
              { label: "Settings", value: Tabs.SETTINGS }
            ]}
            value={tab}
            onChange={setTab}
          />
        </div>
      </div>
      <div className={css.main}>
        {tab === Tabs.SETTINGS ? (
          <ConnectedAccountsSettings />
        ) : (
          <>
            <div className={cx(css.accounts)}>
              <div className={cx(pageCss.block, css.accountsBlock)}>
                <ConnectedAccountsList />
              </div>
            </div>
            <div className={cx(css.display)}>
              <ConnectedTopBar />
              {tab === Tabs.GRAPH && <ConnectedChart />}
              {tab === Tabs.LEDGER && <ConnectedLedgerDisplay />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
