import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store } from "./data/store";
import { Router, Route, visit, FallbackRoute } from "./components/core/routing";
import { ConnectedImporterMain } from "./components/importer/main";
import css from "./App.module.css";
import { ConnectedTransactionsMain } from "./components/transactions/main";
import { LedgersMain } from "./components/ledgers/main";
import { ConnectedOobe } from "./components/oobe/oobe";
import { OverviewMain } from "components/overview/main";
import { ReactComponent as TransactionsIcon } from "assets/icons/transactions.svg";
import { ReactComponent as AccountsIcon } from "assets/icons/accounts.svg";
import { ReactComponent as ImportIcon } from "assets/icons/import.svg";
import { ReactComponent as OverviewIcon } from "assets/icons/overview.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { SettingsMain } from "components/settings/main";

const LazySignInScreen = React.lazy(() =>
  import("components/auth/auth").then(m => ({
    default: m.ConnectedSignInScreen
  }))
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedOobe />
      <Suspense fallback={<div>Loading</div>}>
        <LazySignInScreen />
      </Suspense>
      <Router>
        <div className={css.leftNavWrapper}>
          <nav className={css.leftNav}>
            <div className={css.logoWrapper}>
              <div className={css.logo} />
            </div>
            <ul>
              <li>
                <button onClick={() => visit("/")}>
                  <span className={css.navIcon}>
                    <OverviewIcon width={18} height={18} />
                  </span>

                  <span className={css.navText}>Overview</span>
                </button>
              </li>
              <li>
                <button onClick={() => visit("/import")}>
                  <span className={css.navIcon}>
                    <ImportIcon width={18} height={18} />
                  </span>
                  <span className={css.navText}>Import</span>
                </button>
              </li>
              <li>
                <button onClick={() => visit("/accounts")}>
                  <span className={css.navIcon}>
                    <AccountsIcon width={18} height={18} />
                  </span>

                  <span className={css.navText}>Accounts</span>
                </button>
              </li>
              <li>
                <button onClick={() => visit("/transactions")}>
                  <span className={css.navIcon}>
                    <TransactionsIcon width={18} height={18} />
                  </span>
                  <span className={css.navText}>Transactions</span>
                </button>
              </li>
              <li>
                <button onClick={() => visit("/settings")}>
                  <span className={css.navIcon}>
                    <SettingsIcon width={18} height={18} />
                  </span>
                  <span className={css.navText}>Settings</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <div className={css.mainContent}>
          <Route match="/import">
            <ConnectedImporterMain />
          </Route>
          <Route match="/transactions">
            <ConnectedTransactionsMain />
          </Route>
          <Route match="/accounts">
            <LedgersMain />
          </Route>
          <Route match="/settings">
            <SettingsMain />
          </Route>
          <Route match="/">
            <OverviewMain />
          </Route>
          <FallbackRoute>404 Not Found</FallbackRoute>
          <div className={css.footer}>
            Copyright {new Date().getFullYear()} Ducount. Build{" "}
            {process.env.REACT_APP_BUILD_VERSION || "unknown"}
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
