import React from "react";
import { DateTime } from "luxon";
import {
  PredefinedDateRange,
  DateRangeData,
  datetimeRange
} from "lib/datetime/date_range";

function readableName(range: PredefinedDateRange): string {
  switch (range) {
    case PredefinedDateRange.THIS_WEEK:
      return "This week";
    case PredefinedDateRange.LAST_WEEK:
      return "Last week";
    case PredefinedDateRange.THIS_MONTH:
      return "This month";
    case PredefinedDateRange.LAST_MONTH:
      return "Last month";
    case PredefinedDateRange.THIS_YEAR:
      return "This year";
    case PredefinedDateRange.LAST_YEAR:
      return "Last year";
  }
}

export const DateRangePicker: React.FC<{
  range: DateRangeData;
  onChange(r: DateRangeData): void;
}> = props => (
  <span>
    <label>
      Time range:
      <select
        onChange={e => {
          if (e.currentTarget.value === "_CUSTOM") {
            props.onChange({
              ...props.range,
              range: "_CUSTOM"
            });
          } else {
            const range = e.currentTarget.value as PredefinedDateRange;
            props.onChange(datetimeRange(range));
          }
        }}
        value={props.range.range}
      >
        {[
          PredefinedDateRange.THIS_WEEK,
          PredefinedDateRange.LAST_WEEK,
          PredefinedDateRange.THIS_MONTH,
          PredefinedDateRange.LAST_MONTH,
          PredefinedDateRange.THIS_YEAR,
          PredefinedDateRange.LAST_YEAR
        ].map(range => (
          <option value={range} key={range}>
            {readableName(range)}
          </option>
        ))}
        <option value="_CUSTOM">Custom</option>
      </select>
    </label>
    {props.range.range === "_CUSTOM" && (
      <span>
        <input
          type="date"
          value={props.range.startTime.toISODate()}
          onChange={e =>
            props.onChange({
              ...props.range,
              startTime: DateTime.fromISO(e.currentTarget.value)
            })
          }
        />{" "}
        -{" "}
        <input
          type="date"
          value={props.range.endTime.toISODate()}
          onChange={e =>
            props.onChange({
              ...props.range,
              endTime: DateTime.fromISO(e.currentTarget.value)
            })
          }
        />
      </span>
    )}
  </span>
);
