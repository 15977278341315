import React, { PureComponent } from "react";
import cx from "classnames";
import { UUID } from "../../lib/core/uuid";
import { connect } from "react-redux";
import {
  accountForestSelector,
  AccountTree
} from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import { setSelectedAccount } from "../../data/ledgers/actions";
import { ReactComponent as CaretRight } from "assets/font-awesome-solid/caret-right.svg";
import { ReactComponent as CaretDown } from "assets/font-awesome-solid/caret-down.svg";
import css from "./accounts_list.module.css";
import buttonCss from "../styles/button.module.css";
import { selectedAccountIdSelector } from "data/ledgers/selectors";
import { IS_PLACEHOLDER } from "data/accounts/tags";

function traverse(root: AccountTree, func: (node: AccountTree) => void) {
  func(root);
  for (const children of root.children) {
    traverse(children, func);
  }
}

class CollapsibleAccounts extends PureComponent<
  {
    tree: AccountTree;
    onSelectAccounts: typeof setSelectedAccount;
    level: number;
    selectedAccounts: UUID[];
  },
  { collapsed: boolean }
> {
  state = { collapsed: false };

  private toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const allSubAccountIds: UUID[] = [];
    traverse(
      this.props.tree,
      node => node.account && allSubAccountIds.push(node.account.id)
    );
    const isSelected =
      this.props.tree.account &&
      this.props.selectedAccounts.includes(this.props.tree.account.id);
    return (
      <li>
        <div
          className={cx(css.accountLine, {
            [css.selectedAccountLine]: isSelected
          })}
          style={{
            paddingLeft: this.props.level * 16
          }}
        >
          {this.props.tree.children.length > 0 ? (
            <button
              onClick={this.toggle}
              className={cx(buttonCss.styleless, css.caret)}
            >
              {this.state.collapsed ? (
                <CaretRight width={12} height={12} />
              ) : (
                <CaretDown width={12} height={12} />
              )}
            </button>
          ) : null}
          <button
            onClick={e => {
              if (e.shiftKey) {
                if (isSelected) {
                  this.props.onSelectAccounts(
                    this.props.selectedAccounts.filter(
                      id => id !== this.props.tree.account!.id
                    )
                  );
                } else if (this.props.tree.account) {
                  this.props.onSelectAccounts(
                    this.props.selectedAccounts.concat([
                      this.props.tree.account.id
                    ])
                  );
                }
              } else {
                this.props.onSelectAccounts(allSubAccountIds);
              }
            }}
            className={buttonCss.styleless}
          >
            {this.props.tree.name}
          </button>
          {allSubAccountIds.length > 1 &&
            this.props.tree.account &&
            !this.props.tree.account.tags[IS_PLACEHOLDER] && (
              <button
                onClick={() =>
                  this.props.onSelectAccounts([this.props.tree.account!.id])
                }
                className={cx(buttonCss.styleless, css.onlyButton)}
              >
                Only
              </button>
            )}
        </div>
        {this.state.collapsed ? null : (
          <ul className={css.accountList}>
            {this.props.tree.children.map(tree => (
              <CollapsibleAccounts
                key={(tree.account && tree.account.id) || tree.fullName}
                tree={tree}
                onSelectAccounts={this.props.onSelectAccounts}
                level={this.props.level + 1}
                selectedAccounts={this.props.selectedAccounts}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export class AccountsList extends PureComponent<
  {
    accountForest: ReturnType<typeof accountForestSelector>;
    onSelectAccounts: typeof setSelectedAccount;
    selectedAccounts: UUID[];
  },
  {}
> {
  render() {
    const forest = this.props.accountForest;

    return (
      <ul className={css.rootAccountList}>
        {forest.map(tree => (
          <CollapsibleAccounts
            key={tree.account && tree.account.id}
            tree={tree}
            onSelectAccounts={this.props.onSelectAccounts}
            level={0}
            selectedAccounts={this.props.selectedAccounts}
          />
        ))}
      </ul>
    );
  }
}

export const ConnectedAccountsList = connect(
  (state: AppState) => ({
    accountForest: accountForestSelector(state),
    selectedAccounts: selectedAccountIdSelector(state)
  }),
  {
    onSelectAccounts: setSelectedAccount
  }
)(AccountsList);
