import React, { PureComponent } from "react";

import { ConnectedImporter } from "./importer";
import { Dispatch, AppState } from "../../data/store";
import { loadData } from "../../data/importer/actions";
import { connect } from "react-redux";
import {
  importerNsSelector,
  externalAccountDataListSelector
} from "../../data/importer/selectors";
import { accountsNsSelector } from "../../data/accounts/selectors";
import { ConnectedImporterSidebar } from "./sidebar";
import pageCss from "components/styles/page.module.css";

import css from "./main.module.css";
import { ConnectedUpload } from "./upload";
import { SegmentedButton } from "components/common/segmented_button";

enum Tabs {
  UPLOAD = "UPLOAD",
  MANAGE = "MANAGE"
}

type Props = {
  dispatch: Dispatch;
  isReady: boolean;
  isImporterReady: boolean;
  hasExternalAccountData: boolean;
};

type State = {
  tab?: Tabs;
};

export class ImporterMain extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.stateFromProps(props);
  }

  componentDidMount() {
    if (this.props.isReady && !this.props.isImporterReady) {
      this.props.dispatch(loadData());
    }
  }

  stateFromProps(props: Props): Partial<State> {
    if (this.state && this.state.tab) {
      return {};
    }
    if (props.isReady) {
      if (props.hasExternalAccountData) {
        return {
          tab: Tabs.MANAGE
        };
      } else {
        return { tab: Tabs.UPLOAD };
      }
    }
    return {};
  }

  componentDidUpdate() {
    this.setState(this.stateFromProps(this.props));
  }

  onUploaded = () => this.setState({ tab: Tabs.MANAGE });

  onTabChange = (tab: Tabs) => {
    this.setState({ tab });
  };

  render() {
    if (!this.props.isReady) {
      return null;
    }
    return (
      <div className={pageCss.pageWrapper}>
        <div className={pageCss.pageTitleWrapper}>
          <h1 className={pageCss.pageTitle}>Import Transactions</h1>
          <div className={pageCss.pageTitleNav}>
            <SegmentedButton
              buttons={[
                { label: "Upload", value: Tabs.UPLOAD },
                { label: "Manage", value: Tabs.MANAGE }
              ]}
              value={this.state.tab}
              onChange={this.onTabChange}
            />
          </div>
        </div>
        <div className={css.mainContent}>
          {this.state.tab === Tabs.UPLOAD && (
            <ConnectedUpload onUploaded={this.onUploaded} />
          )}
          {this.state.tab === Tabs.MANAGE && (
            <div className={css.entries}>
              <ConnectedImporter />
            </div>
          )}
          {this.state.tab === Tabs.MANAGE && (
            <div className={css.sidebar}>
              <ConnectedImporterSidebar />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ConnectedImporterMain = connect((state: AppState) => ({
  isReady: !!accountsNsSelector(state).ready,
  isImporterReady: !!importerNsSelector(state).ready,
  hasExternalAccountData: externalAccountDataListSelector(state).length > 0
}))(ImporterMain);
