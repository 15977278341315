import {
  Transaction,
  Split,
  Account,
  ImportedEntry,
  ExternalAccount
} from "../model/bookkeeping";
import { UUID } from "../lib/core/uuid";
import { BackupDataSchema } from "./backup";
import { TransactionMutations } from "data/accounts/types";

export abstract class Persist {
  private newAccountHandler: (() => void) | undefined = undefined;

  registerNewAccountHandler(handler: () => void) {
    this.newAccountHandler = handler;
  }

  runNewAccountHandler() {
    if (this.newAccountHandler) {
      this.newAccountHandler();
    }
  }

  abstract addTransactions(
    transactions: Transaction[],
    splits: Split[]
  ): Promise<void>;

  abstract getAccountData(): Promise<{
    accounts: Account[];
    transactions: Transaction[];
    splits: Split[];
  }>;

  abstract async addAccounts(accounts: Account[]): Promise<void>;

  abstract getImporterData(): Promise<{
    importedEntries: ImportedEntry[];
    externalAccounts: ExternalAccount[];
  }>;

  abstract updateImportedEntrySplitIds(mapping: Map<UUID, UUID>): Promise<void>;

  abstract addExternalAccountsAndEntries(
    externalAccount: ExternalAccount | undefined,
    entries: ImportedEntry[]
  ): Promise<UUID[]>;

  abstract updateExternalAccount(
    externalAccount: Partial<ExternalAccount> & Pick<ExternalAccount, "id">
  ): Promise<void>;

  abstract updateAccountTags(
    accountId: string,
    tags: { [tag: string]: string }
  ): Promise<void>;

  abstract updateTransactionsAndSplits(
    mutations: TransactionMutations
  ): Promise<void>;

  abstract backup(): Promise<BackupDataSchema>;

  abstract restore(data: BackupDataSchema): Promise<void>;
}
