import React from "react";
import { Transaction, Split } from "../../model/bookkeeping";
import { accountsMapSelector } from "../../data/accounts/selectors";
import { formatDebitCredit } from "../../model/scaled_value";
import { DateTime } from "luxon";
import css from "./transactions.module.css";
import { dateToNumber } from "lib/datetime/date";

export const Transactions: React.FC<{
  transactions: Transaction[];
  splitsByTransaction: { [id: string]: Split[] };
  accountsMap: ReturnType<typeof accountsMapSelector>;
  selectedIds: { [id: string]: boolean };
  setSelectedIds(ids: { [id: string]: boolean }): void;
}> = props => {
  const {
    transactions,
    splitsByTransaction,
    accountsMap,
    selectedIds,
    setSelectedIds
  } = props;

  return (
    <div>
      {transactions.length === 0 && (
        <div className={css.empty}>No transactions found</div>
      )}
      {transactions.map((tx, i) => {
        let date = undefined;
        if (
          i === 0 ||
          dateToNumber(tx.datetime.datetime) !==
            dateToNumber(transactions[i - 1].datetime.datetime)
        ) {
          date = tx.datetime.datetime.toLocaleString(DateTime.DATE_MED);
        }

        return (
          <div id={tx.id} key={tx.id} className={css.transactionLine}>
            <div className={date ? css.dateBox : css.emptyDateBox}>{date}</div>
            <div className={css.checkboxBox}>
              <input
                type="checkbox"
                checked={!!selectedIds[tx.id]}
                onChange={e =>
                  setSelectedIds({
                    ...selectedIds,
                    [tx.id]: e.currentTarget.checked
                  })
                }
              />
            </div>
            <div className={css.splitsBox}>
              <p>{tx.memo}</p>
              <table>
                <tbody>
                  {(splitsByTransaction[tx.id] || []).map(split => {
                    const [dr, cr] = formatDebitCredit(split.valueScaled);
                    return (
                      <tr key={split.id}>
                        <td>
                          {
                            (accountsMap.get(split.accountId) || { name: "" })
                              .name
                          }
                        </td>
                        <td className={css.amountCell}>{dr}</td>
                        <td className={css.amountCell}>{cr}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </div>
  );
};
