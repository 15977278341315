import React, { PureComponent } from "react";
import cx from "classnames";
import { Split, Transaction } from "../../model/bookkeeping";
import { connect } from "react-redux";
import {
  transactionByIdSelector,
  accountsMapSelector,
  splitsByTransactionSelector
} from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import {
  filteredSplitsSelector,
  checkedSplitIdsSelector,
  checkedSplitsSelector
} from "../../data/ledgers/selectors";
import { formatDebitCredit, zero, sum } from "../../model/scaled_value";
import { ReactComponent as CaretLeft } from "assets/font-awesome-solid/caret-left.svg";
import { ReactComponent as CaretDown } from "assets/font-awesome-solid/caret-down.svg";
import { AccountDisplay } from "components/common/account";
import buttonCss from "../styles/button.module.css";
import css from "./ledger.module.css";
import {
  setSelectedAccount,
  setSplitSelected,
  clearSplitSelected
} from "data/ledgers/actions";
import pageCss from "components/styles/page.module.css";
import { UUID } from "lib/core/uuid";

interface RowProps {
  index: number;
  split: Split;
  transaction: Transaction;
  checked: boolean;
  accountsMap: ReturnType<typeof accountsMapSelector>;
  splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
  setSelectedAccount: typeof setSelectedAccount;
  onSelectionChange(id: UUID, selected: boolean): void;
}

interface RowState {
  showFullTransaction?: boolean;
}

class Row extends PureComponent<RowProps, RowState> {
  state: RowState = {};

  render() {
    const { showFullTransaction } = this.state;
    const {
      index,
      split,
      transaction,
      accountsMap,
      splitsByTransaction,
      checked: selected
    } = this.props;
    const [dr, cr] = formatDebitCredit(split.valueScaled);
    const splitsInTransaction = splitsByTransaction[split.transactionId];

    const otherSplits = splitsInTransaction.filter(s => s.id !== split.id);

    const otherAccount = showFullTransaction ? null : otherSplits.length > 1 ? (
      <span>Multileg</span>
    ) : (
      <button
        className={buttonCss.styleless}
        onClick={() =>
          this.props.setSelectedAccount([otherSplits[0].accountId])
        }
      >
        <AccountDisplay
          accountName={accountsMap.get(otherSplits[0].accountId)!.name}
        />
      </button>
    );

    const fullTransaction = showFullTransaction
      ? otherSplits.map(split => {
          const [dr, cr] = formatDebitCredit(split.valueScaled);
          return (
            <tr className={css.expandRow} key={split.id}>
              <td className={css.checkboxCell} />
              <td className={css.dateCell}>
                {split.datetime.datetime.toFormat("D")}
              </td>
              <td>{split.memo || transaction.memo}</td>
              <td className={css.accountCell}>
                <button
                  className={buttonCss.styleless}
                  onClick={() =>
                    this.props.setSelectedAccount([split.accountId])
                  }
                >
                  <AccountDisplay
                    accountName={accountsMap.get(split.accountId)!.name}
                  />
                </button>
              </td>
              <td className={css.accountCell} />
              <td className={css.amountCell}>{dr}</td>
              <td className={css.amountCell}>{cr}</td>
              <td className={css.expandCell} />
            </tr>
          );
        })
      : [];

    const mainRow = (
      <tr
        key={split.id}
        className={cx({
          [css.evenRow]: index % 2,
          [css.expandedRow]: showFullTransaction
        })}
      >
        <td className={css.checkboxCell}>
          <input
            type="checkbox"
            checked={selected}
            onChange={e =>
              this.props.onSelectionChange(split.id, e.currentTarget.checked)
            }
          />
        </td>
        <td className={css.dateCell}>
          {split.datetime.datetime.toFormat("D")}
        </td>
        <td>{split.memo || transaction.memo}</td>
        <td className={css.accountCell}>
          <AccountDisplay
            accountName={accountsMap.get(split.accountId)!.name}
          />
        </td>
        <td className={css.accountCell}>{otherAccount}</td>
        <td className={css.amountCell}>{dr}</td>
        <td className={css.amountCell}>{cr}</td>
        <td className={css.expandCell}>
          <button
            className={buttonCss.styleless}
            onClick={() =>
              this.setState({
                showFullTransaction: !showFullTransaction
              })
            }
          >
            {showFullTransaction ? (
              <CaretDown width={12} height={12} />
            ) : (
              <CaretLeft width={12} height={12} />
            )}
          </button>
        </td>
      </tr>
    );
    return [mainRow, ...fullTransaction];
  }
}

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
};

class IndetermineCheckbox extends PureComponent<CheckboxProps> {
  ref = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.indeterminate = !!this.props.indeterminate;
    }
  }

  componentDidUpdate(prevProps: CheckboxProps) {
    if (
      this.props.indeterminate !== prevProps.indeterminate &&
      this.ref.current
    ) {
      this.ref.current.indeterminate = !!this.props.indeterminate;
    }
  }

  render() {
    const { indeterminate: indetermine, ...rest } = this.props;

    return <input type="checkbox" ref={this.ref} {...rest} />;
  }
}

class SplitsDisplay extends PureComponent<
  {
    splits: Split[];
    transactions: { [id: string]: Transaction };
    accountsMap: ReturnType<typeof accountsMapSelector>;
    splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
    checkedSplitIds: ReturnType<typeof checkedSplitIdsSelector>;
    checkedSplits: ReturnType<typeof checkedSplitsSelector>;

    setSelectedAccount: typeof setSelectedAccount;
    setSplitSelected: typeof setSplitSelected;
    clearSplitSelected: typeof clearSplitSelected;
  },
  {}
> {
  changeSelection = (id: UUID, selected: boolean) => {
    this.props.setSplitSelected({ [id]: selected });
  };

  checkAll = () => {
    this.props.clearSplitSelected();
    this.props.setSplitSelected(
      this.props.splits.reduce(
        (acc, split) => {
          acc[split.id] = true;
          return acc;
        },
        {} as { [k: string]: boolean }
      )
    );
  };

  uncheckAll = () => {
    this.props.clearSplitSelected();
  };

  footer() {
    const { checkedSplits } = this.props;
    const total = checkedSplits.reduce(
      (acc, split) => sum(acc, split.valueScaled),
      zero()
    );
    const [dr, cr] = formatDebitCredit(total);
    return (
      <tfoot>
        <tr>
          <td className={css.checkboxCell} />
          <td className={css.dateCell} />
          <td>Selected Legs</td>
          <td className={css.accountCell} />
          <td className={css.accountCell} />
          <td className={css.amountCell}>{dr}</td>
          <td className={css.amountCell}>{cr}</td>
          <td className={css.expandCell} />
        </tr>
      </tfoot>
    );
  }

  render() {
    const {
      splits,
      transactions,
      accountsMap,
      splitsByTransaction,
      checkedSplitIds,
      checkedSplits
    } = this.props;

    const countSelected = checkedSplits.length;

    return (
      <div className={cx(css.wrapper, pageCss.block)}>
        <table className={css.ledgerTable}>
          <thead>
            <tr>
              <th className={css.checkboxCell}>
                <IndetermineCheckbox
                  checked={countSelected > 0}
                  indeterminate={
                    countSelected > 0 && countSelected < splits.length
                  }
                  onChange={e =>
                    e.currentTarget.checked
                      ? this.checkAll()
                      : this.uncheckAll()
                  }
                />
              </th>
              <th className={css.dateCell}>Date</th>
              <th>Memo</th>
              <th className={css.accountCell}>Account</th>
              <th className={css.accountCell}>Transfer Account</th>
              <th className={css.amountCell}>Debit</th>
              <th className={css.amountCell}>Credit</th>
              <th className={css.expandCell} />
            </tr>
          </thead>
          <tbody>
            {splits.map(
              (split, i) =>
                transactions[split.transactionId] && (
                  <Row
                    key={split.id}
                    index={i + 1}
                    split={split}
                    transaction={transactions[split.transactionId]}
                    accountsMap={accountsMap}
                    splitsByTransaction={splitsByTransaction}
                    setSelectedAccount={this.props.setSelectedAccount}
                    checked={!!checkedSplitIds[split.id]}
                    onSelectionChange={this.changeSelection}
                  />
                )
            )}
            {splits.length === 0 && (
              <tr>
                <td colSpan={8} className={css.empty}>
                  No entries found
                </td>
              </tr>
            )}
          </tbody>
          {checkedSplits.length > 0 && this.footer()}
        </table>
      </div>
    );
  }
}

export const ConnectedSplitsDisplay = connect(
  (state: AppState) => ({
    splits: filteredSplitsSelector(state),
    transactions: transactionByIdSelector(state),
    accountsMap: accountsMapSelector(state),
    splitsByTransaction: splitsByTransactionSelector(state),
    checkedSplitIds: checkedSplitIdsSelector(state),
    checkedSplits: checkedSplitsSelector(state)
  }),
  {
    setSelectedAccount,
    setSplitSelected,
    clearSplitSelected
  }
)(SplitsDisplay);
