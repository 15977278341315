import { reducerInNs } from "data/utils";
import { AUTH_NS, AuthState, AuthStatus } from "./types";
import { AUTH_DEFAULT_STATE } from "./default_state";
import { AppState } from "data/store";
import { AuthAction } from "./action_creators";

export const authReducer = reducerInNs(
  AUTH_NS,
  (
    state: AuthState = AUTH_DEFAULT_STATE,
    action: AuthAction,
    fullState: AppState
  ) => {
    switch (action.type) {
      case "AUTH/SET_USER": {
        if (action.payload.user) {
          return {
            ...state,
            status: AuthStatus.LOGGED_IN
          };
        } else {
          return {
            ...state,
            status: AuthStatus.LOGGED_OUT
          };
        }
      }
    }
    return state;
  }
);
