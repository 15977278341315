import React, { useState } from "react";
import cx from "classnames";
import pageCss from "components/styles/page.module.css";
import css from "./accounts_settings.module.css";
import { connect } from "react-redux";
import { AppState } from "data/store";
import { accountForestSelector, AccountTree } from "data/accounts/selectors";
import { Account } from "model/bookkeeping";
import { IS_PLACEHOLDER } from "data/accounts/tags";
import { ReactComponent as CaretRight } from "assets/font-awesome-solid/caret-right.svg";
import { ReactComponent as CaretDown } from "assets/font-awesome-solid/caret-down.svg";
import buttonCss from "../styles/button.module.css";
import { addAccounts } from "data/accounts/actions";
import { PATH_SEPARATOR } from "lib/accounts/hierarchy";
import { newUuid } from "lib/core/uuid";

export const SingleAccount: React.FC<{
  account: Account;
  onCreateAccount(name: string): void;
}> = React.memo(props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [inAccountCreation, setInAccountCreation] = useState(false);
  const [newAccountName, setNewAccountName] = useState("");
  return (
    <div>
      <h3>
        <button
          className={buttonCss.styleless}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <CaretDown width={12} height={12} />
          ) : (
            <CaretRight width={12} height={12} />
          )}
        </button>
        {props.account.name}
      </h3>
      <div style={{ display: isExpanded ? "block" : "none" }}>
        <div>
          <label>
            <input
              type="checkbox"
              checked={!!props.account.tags[IS_PLACEHOLDER]}
            />
            Is placeholder account
          </label>
        </div>
        <div>
          {inAccountCreation ? (
            <>
              <input
                type="text"
                placeholder="Account name"
                value={newAccountName}
                onChange={e => setNewAccountName(e.currentTarget.value)}
              />
              <button
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
                onClick={() => {
                  props.onCreateAccount(newAccountName);
                  setNewAccountName("");
                  setInAccountCreation(false);
                }}
              >
                OK
              </button>
            </>
          ) : (
            <button
              className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              onClick={() => setInAccountCreation(true)}
            >
              Create a new subaccount
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

SingleAccount.displayName = "SingleAccount";

export const AccountsSettings: React.FC<{
  accountForest: ReturnType<typeof accountForestSelector>;
  addAccounts(accounts: Account[]): void;
}> = props => {
  const [searchTerm, setSearchTerm] = useState("");
  const accounts: Account[] = [];
  const normalizedSearchTerm = searchTerm.toLocaleLowerCase();
  function iterate(tree: AccountTree) {
    if (
      tree.account &&
      (!searchTerm ||
        tree.account.name.toLocaleLowerCase().includes(normalizedSearchTerm))
    ) {
      accounts.push(tree.account);
    }
    for (const c of tree.children) {
      iterate(c);
    }
  }

  for (const c of props.accountForest) {
    iterate(c);
  }

  return (
    <div className={cx(pageCss.block, css.accounts)}>
      <div className={css.searchBar}>
        <input
          type="search"
          placeholder="Search account"
          onChange={e => setSearchTerm(e.currentTarget.value)}
        />
      </div>

      {accounts.map(account => (
        <SingleAccount
          key={account.id}
          account={account}
          onCreateAccount={name => {
            let fullName = account.name;
            const newAccounts: Account[] = [];
            for (const part of name.split(PATH_SEPARATOR)) {
              fullName += PATH_SEPARATOR;
              fullName += part;
              newAccounts.push({
                id: newUuid(),
                name: fullName,
                tags: {}
              });
            }
            props.addAccounts(newAccounts);
          }}
        />
      ))}
    </div>
  );
};

AccountsSettings.displayName = "AccountsSettings";

export const ConnectedAccountsSettings = connect(
  (state: AppState) => ({
    accountForest: accountForestSelector(state)
  }),
  {
    addAccounts
  }
)(AccountsSettings);
