import { reducerInNs } from "../utils";
import {
  IMPORTER_NS,
  ImporterState,
  entryIsCandidate,
  CandidateEntry
} from "./types";
import { AppState } from "../store";
import { ImporterAction } from "./action_creators";
import { IMPORTER_DEFAULT_STATE } from "./default_state";
import { newUuid, UUID } from "../../lib/core/uuid";
import { negate } from "../../model/scaled_value";
import produce from "immer";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { beginningOfTime } from "lib/datetime/date";
import { openingBalanceAccountSelector } from "data/accounts/selectors";
import { ImportedEntry } from "model/bookkeeping";

const loadExternalDataReducer = reducerInNs(
  IMPORTER_NS,
  (
    state: ImporterState = IMPORTER_DEFAULT_STATE,
    action: ImporterAction,
    fullState: AppState
  ) => {
    switch (action.type) {
      case "IMPORTER/FILES_PARSED": {
        const updatedExtAccounts: ImporterState["externalAccountsData"] = {};
        const updatedEntries: ImporterState["entries"] = {};
        for (const data of action.payload) {
          const [account, entries] = data;

          for (const entry of entries) {
            updatedEntries[entry.id] = {
              entry,
              accountExternalId: account.externalId
            };
          }

          const externalAccount =
            state.externalAccountsData[account.externalId];

          if (externalAccount) {
            const newEntries = [
              ...externalAccount.entryIds,
              ...entries.map(e => e.id)
            ];
            const getEntryById = (id: UUID) =>
              updatedEntries[id] || state.entries[id];

            const sortedNewEntries = sortBy(newEntries, e =>
              getEntryById(e).entry.datetime.datetime.toMillis()
            );
            updatedExtAccounts[account.externalId] = {
              ...externalAccount,
              entryIds: sortedNewEntries
            };
          } else {
            updatedExtAccounts[account.externalId] = {
              account,
              entryIds: entries.map(entry => entry.id)
            };
          }
        }

        return {
          ...state,
          externalAccountsData: {
            ...state.externalAccountsData,
            ...updatedExtAccounts
          },
          entries: {
            // Old entries take precedence
            // Because of potential unsaved state
            ...updatedEntries,
            ...state.entries
          }
        };
      }

      case "IMPORTER/UPDATE_EXTERNAL_ACCOUNTS": {
        return produce(state, draftState => {
          const { accounts } = action.payload;
          for (const account of accounts) {
            Object.assign(
              draftState.externalAccountsData[account.externalId].account,
              account
            );
          }
        });
      }

      case "IMPORTER/SET_OTHER_ACCOUNT": {
        return produce(state, draftState => {
          const { entry, thisAccountId, otherAccountId } = action.payload;
          clearTransactions([entry.entry], state, draftState);
          const transactionId = newUuid();
          const splitId = newUuid();
          const newTransaction = {
            transaction: {
              id: transactionId,
              memo: entry.entry.memo,
              datetime: entry.entry.datetime,
              tags: {}
            },

            splits: [
              {
                id: splitId,
                transactionId,
                accountId: thisAccountId,
                valueScaled: entry.entry.valueScaled,
                datetime: entry.entry.datetime,
                tags: {}
              },
              {
                id: newUuid(),
                transactionId,
                accountId: otherAccountId,
                valueScaled: negate(entry.entry.valueScaled),
                datetime: entry.entry.datetime,
                tags: {}
              }
            ]
          };
          draftState.candidateTransactions[transactionId] = newTransaction;
          const candidateEntry = draftState.entries[
            entry.entry.id
          ] as CandidateEntry;
          candidateEntry.candidateSplitId = splitId;
          candidateEntry.candidateTransactionId = transactionId;
          candidateEntry.autoCategorizeP = undefined;
        });
      }
      case "IMPORTER/CLEAR_TRANSACTION": {
        const { entry } = action.payload;
        return produce(state, draftState => {
          clearTransactions([entry.entry], state, draftState);
        });
      }
      case "IMPORTER/SET_AUTO_CATEGORIZE_P": {
        const { p, entry } = action.payload;

        return produce(state, draftState => {
          draftState.entries[entry.entry.id].autoCategorizeP = p;
        });
      }
      case "IMPORTER/ADD_BALANCING_TRANSACTION": {
        const { externalAccount, account, openingBalance } = action.payload;
        const otherAccount = openingBalanceAccountSelector(fullState);
        if (!otherAccount) {
          return state;
        }
        return produce(state, draftState => {
          const transactionId = newUuid();
          const thisSplit = {
            id: newUuid(),
            transactionId,
            accountId: account.id,
            valueScaled: openingBalance,
            datetime: beginningOfTime(),
            tags: {}
          };
          const thatSplit = {
            id: newUuid(),
            transactionId,
            accountId: otherAccount.id,
            valueScaled: negate(openingBalance),
            datetime: beginningOfTime(),
            tags: {}
          };
          draftState.candidateTransactions[transactionId] = {
            transaction: {
              id: transactionId,
              memo: "OPENING BALANCE",
              datetime: beginningOfTime(),
              tags: {}
            },
            splits: [thisSplit, thatSplit]
          };
          draftState.externalAccountsData[
            externalAccount.externalId
          ].balancingTransaction = transactionId;
        });
      }
      case "IMPORTER/MARK_ENTRIES_IMPORTED": {
        return produce(state, draftState => {
          const { transactionIds, splitIds } = action.payload;
          for (const tId of action.payload.transactionIds) {
            delete draftState.candidateTransactions[tId];
          }
          forEach(draftState.externalAccountsData, accountData => {
            if (
              accountData.balancingTransaction &&
              transactionIds.includes(accountData.balancingTransaction)
            ) {
              accountData.balancingTransaction = undefined;
            }
          });
          forEach(draftState.entries, entry => {
            if (
              entryIsCandidate(entry) &&
              splitIds.includes(entry.candidateSplitId)
            ) {
              entry.entry.splitId = entry.candidateSplitId;
              delete draftState.candidateTransactions[
                entry.candidateTransactionId
              ];
              delete entry.candidateSplitId;
              delete entry.candidateTransactionId;
            }
          });
        });
      }
      case "IMPORTER/ADD_ACCOUNTS_ENTRIES": {
        return produce(state, draftState => {
          const { externalAccounts, importedEntries } = action.payload;
          const entriesByAccount = groupBy(
            importedEntries,
            entry => entry.externalAccountId
          );
          for (const extAccount of externalAccounts) {
            draftState.externalAccountsData[extAccount.externalId] = {
              account: extAccount,
              entryIds: (entriesByAccount[extAccount.id] || []).map(
                entry => entry.id
              )
            };
            for (const entry of entriesByAccount[extAccount.id] || []) {
              draftState.entries[entry.id] = {
                entry,
                accountExternalId: extAccount.externalId
              };
            }
          }
        });
      }
      case "IMPORTER/READY": {
        return { ...state, ready: true };
      }
      case "IMPORTER/SET_ENTRY_SELECTED": {
        return produce(state, draftState => {
          if (action.payload.status) {
            draftState.selectedEntries[action.payload.id] = true;
          } else {
            delete draftState.selectedEntries[action.payload.id];
          }
        });
      }
      case "IMPORTER/CREATE_TRANSACTION": {
        return produce(state, draftState => {
          const { entries, splits, transaction } = action.payload;

          clearTransactions(entries, state, draftState);

          for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];

            const candidateEntry = draftState.entries[
              entry.id
            ] as CandidateEntry;

            const split = splits[i];

            candidateEntry.candidateSplitId = split.id;
            candidateEntry.candidateTransactionId = transaction.id;
            candidateEntry.autoCategorizeP = undefined;
          }
          draftState.candidateTransactions[transaction.id] = {
            transaction,
            splits
          };
        });
      }
      case "IMPORTER/UNSELECT_ALL": {
        const { externalAccount } = action.payload;
        return {
          ...state,
          selectedEntries: Object.keys(state.selectedEntries).reduce(
            (acc, key) => {
              if (
                state.entries[key].accountExternalId !==
                externalAccount.externalId
              ) {
                acc[key] = true;
              }
              return acc;
            },
            {} as (typeof state)["selectedEntries"]
          )
        };
      }
      case "IMPORTER/SET_SHOW_IMPORTED_ENTRIES": {
        return { ...state, showImportedEntries: action.payload };
      }
      case "IMPORTER/IMPORT_CSV": {
        return {
          ...state,
          csv: action.payload.data
        };
      }
      case "IMPORTER/CLEAR_CSV": {
        return {
          ...state,
          csv: undefined
        };
      }
    }
    return state;
  }
);

function clearTransactions(
  entries: ImportedEntry[],
  state: ImporterState,
  draftState: ImporterState
) {
  const transactionsToDelete = new Set<UUID>();
  for (const entry of entries) {
    const candidateEntry = state.entries[entry.id];
    if (entryIsCandidate(candidateEntry)) {
      transactionsToDelete.add(candidateEntry.candidateTransactionId);
      delete draftState.candidateTransactions[
        candidateEntry.candidateTransactionId
      ];
    }
  }
  forEach(draftState.entries, entry => {
    if (entryIsCandidate(entry)) {
      if (transactionsToDelete.has(entry.candidateTransactionId)) {
        delete entry.candidateTransactionId;
        delete entry.candidateSplitId;
        entry.autoCategorizeP = undefined;
      }
    }
  });
}

export const importerReducer = loadExternalDataReducer;
