import React, { PureComponent } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { ExternalAccount, Account, Split } from "../../model/bookkeeping";
import {
  setOtherAccount,
  markEntriesImported,
  setEntrySelectedStatus,
  autoCategorize
} from "../../data/importer/actions";
import { Dispatch, AppState } from "../../data/store";
import {
  importerNsSelector,
  candidateTransactionsSelector,
  externalAccountDataListSelector,
  entryDataByIdSelector
} from "../../data/importer/selectors";
import {
  ExternalAccountData,
  entryIsSaved,
  entryIsCandidate,
  Entry,
  CandidateTransaction
} from "../../data/importer/types";
import { unscaleValue, sum, ScaledValue } from "../../model/scaled_value";
import { UUID } from "../../lib/core/uuid";
import {
  accountByExternalIdSelector,
  balanceSelector,
  accountByImportTypeSelector
} from "../../data/accounts/selectors";
import { EXTERNAL_ACCOUNT_IDS } from "../../data/accounts/tags";
import {
  setImportToAccount,
  addTransactions
} from "../../data/accounts/actions";
import map from "lodash/map";
import forEach from "lodash/forEach";
import { ConnectedAccountCreator } from "../ledgers/account_creator";
import { AccountDisplay, ConnectedAccountSelector } from "../common/account";
import { ProbabilityIndicator } from "./widgets/probability_indicator";
import {
  addBalancingTransaction,
  setShowImportedEntries,
  clearTransaction
} from "data/importer/action_creators";
import { ReactComponent as CloseIcon } from "../../assets/font-awesome-solid/times-circle.svg";

import css from "./importer.module.css";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";

interface DerivedProps {
  externalAccountData: ExternalAccountData[];
  accountByExternalId: ReturnType<typeof accountByExternalIdSelector>;
  candidateTransactionById: ReturnType<typeof candidateTransactionsSelector>;
  balanceById: ReturnType<typeof balanceSelector>;
  accountByImportType: ReturnType<typeof accountByImportTypeSelector>;
  selectedEntries: { [id: string]: boolean };
  entryDataById: ReturnType<typeof entryDataByIdSelector>;
  cursorIndex: number;
  cursorAccount?: UUID;
  showImportedEntries: boolean;
}

interface DispatchProps {
  dispatch: Dispatch;
}

interface ImportToAccountOptionsProps {
  externalAccount: ExternalAccount;
  onSetAccount?(accountId: UUID): void;
  newAccountTags?: { [s: string]: string };
  defaultValue?: string;
}

interface ImportToAccountOptionsState {
  accountId?: UUID;
}

class ImportToAccountOptions extends PureComponent<
  ImportToAccountOptionsProps,
  ImportToAccountOptionsState
> {
  state: ImportToAccountOptionsState = {};
  private onChange = (accountId: UUID) => {
    this.setState({ accountId });
  };

  private onSetAccount = () => {
    if (this.props.onSetAccount && this.state.accountId) {
      this.props.onSetAccount(this.state.accountId);
    }
  };

  render() {
    return (
      <div className={css.accountSelectorOverlay}>
        <div className={css.accountSelector}>
          <div>
            External account {this.props.externalAccount.name} does not have a
            corresponding account
          </div>
          <div>
            <p>Select an account to import into</p>
            <ConnectedAccountSelector
              id={this.props.externalAccount.id}
              value={this.state.accountId}
              onChange={this.onChange}
              theme={{
                container: css.accountSelectorInput
              }}
            />
            <button
              onClick={this.onSetAccount}
              disabled={!this.state.accountId}
            >
              OK
            </button>
          </div>
          <hr />
          <div>
            <p>Or, create a new account</p>
            <ConnectedAccountCreator
              defaultValue={this.props.defaultValue}
              onCreated={this.props.onSetAccount}
              tags={this.props.newAccountTags}
            />
          </div>
        </div>
      </div>
    );
  }
}

type EntryLineProps = {
  account: Account | undefined;
  entryData: Entry;
  isSelected: boolean;
  candidateTransaction?: CandidateTransaction;
  onSetOtherAccount(entry: Entry, accountId: UUID): (accountId: string) => void;
  setEntrySelectedStatus(id: string, status: boolean): void;
  onClearTransaction(): void;
};

const EntryLine: React.FC<EntryLineProps> = React.memo(props => {
  const {
    account,
    entryData,
    isSelected,
    candidateTransaction,
    onSetOtherAccount,
    onClearTransaction
  } = props;

  let accountSelector;
  let isSaved = false;
  const entry = entryData.entry;

  if (account) {
    if (entryIsSaved(entryData)) {
      accountSelector = null;
      isSaved = true;
    } else {
      const accountFilter = (a: Account) => !a.tags[EXTERNAL_ACCOUNT_IDS];
      if (candidateTransaction) {
        if (candidateTransaction.splits.length > 2) {
          accountSelector = <div>Multileg</div>;
        } else {
          let id = undefined;
          for (const split of candidateTransaction.splits) {
            if (split.accountId !== account.id) {
              id = split.accountId;
              break;
            }
          }
          accountSelector = (
            <ConnectedAccountSelector
              value={id}
              onChange={onSetOtherAccount(entryData, account.id)}
              accountsFilter={accountFilter}
            />
          );
        }
      } else {
        accountSelector = (
          <ConnectedAccountSelector
            onChange={onSetOtherAccount(entryData, account.id)}
            accountsFilter={accountFilter}
          />
        );
      }
    }
  } else {
    accountSelector = null;
  }

  return (
    <tr
      key={entry.id}
      style={{
        textDecoration: isSaved ? "line-through" : "none"
      }}
    >
      <td className={css.checkboxCell}>
        {entryIsSaved(entryData) ? (
          <input key="disabled" type="checkbox" disabled />
        ) : (
          <input
            type="checkbox"
            checked={isSelected}
            onChange={e =>
              props.setEntrySelectedStatus(entry.id, e.currentTarget.checked)
            }
          />
        )}
      </td>
      <td>{entry.memo}</td>
      <td className={css.dateCell}>{entry.datetime.datetime.toFormat("D")}</td>
      <td className={css.otherAccountSelectorCell}>
        <div className={css.otherAccountSelectorInner}>
          {accountSelector}
          {entryData.autoCategorizeP && (
            <ProbabilityIndicator p={entryData.autoCategorizeP} />
          )}
          {candidateTransaction && (
            <button
              className={css.clearTransactionButton}
              onClick={onClearTransaction}
            >
              <CloseIcon width={16} height={16} />
            </button>
          )}
        </div>
      </td>
      <td className={css.amountCell}>
        {unscaleValue(entry.valueScaled).toFixed(2)}
      </td>
    </tr>
  );
});

EntryLine.displayName = "EntryLine";

class Importer extends PureComponent<DerivedProps & DispatchProps, {}> {
  renderOpeningBalanceCreator(
    account: Account | undefined,
    unsavedSum: ScaledValue,
    balance: ScaledValue | undefined,
    extAccount: ExternalAccount,
    balancingTransaction?: UUID
  ) {
    if (balance === undefined) {
      return null;
    }
    if (balancingTransaction) {
      return <div>Balancing Transaction Created</div>;
    }
    if (
      account &&
      balance !== this.props.balanceById(account.id) + unsavedSum
    ) {
      return (
        <div>
          <p>Account Balance does not match.</p>
          <p>
            Account <AccountDisplay accountName={account.name} /> has balance:
            {unscaleValue((this.props.balanceById(account.id) +
              unsavedSum) as ScaledValue).toFixed(2)}
          </p>
          <button
            onClick={() =>
              this.props.dispatch(
                addBalancingTransaction(extAccount, account, (balance -
                  unsavedSum -
                  this.props.balanceById(account.id)) as ScaledValue)
              )
            }
          >
            Create Balancing Entry
          </button>
        </div>
      );
    }

    return null;
  }

  renderTable() {
    return this.props.externalAccountData.map(
      ({ account: extAccount, entryIds, balancingTransaction }) => {
        const entryDataList = entryIds.map(id => this.props.entryDataById[id]);
        const accnt = this.props.accountByExternalId(extAccount.externalId);
        const unsavedSum = sum(
          ...entryDataList
            .filter(entry => !entryIsSaved(entry))
            .map(entryData => entryData.entry.valueScaled)
        );
        const balance = extAccount.balanceScaled;

        let newAccountName = undefined;
        if (extAccount.type) {
          const accountCategory = this.props.accountByImportType(
            extAccount.type
          );
          if (accountCategory) {
            newAccountName = accountCategory.name + ":" + extAccount.name;
          }
        }

        const displayingEntryDataList = entryDataList.filter(
          e => !e.entry.splitId || this.props.showImportedEntries
        );

        if (
          displayingEntryDataList.length > 0 ||
          balancingTransaction ||
          (accnt && balance !== this.props.balanceById(accnt.id) + unsavedSum)
        ) {
          return (
            <div key={extAccount.id} className={cx(css.account, pageCss.block)}>
              <div
                className={cx({
                  [css.accountUnselected]: !accnt
                })}
              >
                <h3>{extAccount.name}</h3>
                {accnt && <div>Import to: {accnt.name}</div>}
                <table className={css.entryTable}>
                  <tbody>
                    {displayingEntryDataList.map(e => (
                      <EntryLine
                        key={e.entry.id}
                        account={accnt}
                        entryData={e}
                        isSelected={!!this.props.selectedEntries[e.entry.id]}
                        candidateTransaction={
                          entryIsCandidate(e)
                            ? this.props.candidateTransactionById[
                                e.candidateTransactionId
                              ]
                            : undefined
                        }
                        onSetOtherAccount={this.onSetOtherAccount}
                        setEntrySelectedStatus={this.setEntrySelectedStatus}
                        onClearTransaction={() =>
                          this.props.dispatch(clearTransaction(e))
                        }
                      />
                    ))}
                  </tbody>
                  {balance !== undefined && (
                    <tfoot>
                      <tr>
                        <td className={css.checkboxCell} />
                        <td>Balance</td>
                        <td className={css.dateCell} />
                        <td className={css.otherAccountSelectorCell} />
                        <td className={css.amountCell}>
                          {unscaleValue(balance).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
                {this.renderOpeningBalanceCreator(
                  accnt,
                  unsavedSum,
                  balance,
                  extAccount,
                  balancingTransaction
                )}
              </div>
              {!accnt && (
                <ImportToAccountOptions
                  externalAccount={extAccount}
                  onSetAccount={accountId =>
                    this.props.dispatch(
                      setImportToAccount(extAccount, accountId)
                    )
                  }
                  newAccountTags={{
                    [EXTERNAL_ACCOUNT_IDS]: extAccount.externalId
                  }}
                  defaultValue={newAccountName}
                />
              )}
            </div>
          );
        } else {
          return null;
        }
      }
    );
  }

  onSetOtherAccount = (entry: Entry, thisAccountId: UUID) => (
    accountId: UUID
  ) => {
    this.props.dispatch(setOtherAccount(entry, thisAccountId, accountId));
  };

  setEntrySelectedStatus = (id: UUID, status: boolean) => {
    this.props.dispatch(setEntrySelectedStatus(id, status));
  };

  onSaveTransactions = () => {
    const transactions = map(
      this.props.candidateTransactionById,
      v => v.transaction
    );
    const splits: Split[] = [];
    forEach(this.props.candidateTransactionById, v => {
      splits.push(...v.splits);
    });

    this.props.dispatch(
      markEntriesImported(transactions.map(t => t.id), splits.map(t => t.id))
    );
    this.props.dispatch(addTransactions(transactions, splits));
  };

  autoCategorize = () => {
    this.props.dispatch(autoCategorize());
  };

  render() {
    return (
      <div>
        <div className={cx(pageCss.block, css.actions)}>
          <ul className={css.actionList}>
            <li>
              <button
                onClick={this.onSaveTransactions}
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
              >
                Save
              </button>
            </li>
            <li>
              <button
                onClick={this.autoCategorize}
                className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              >
                Auto Categorize
              </button>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  checked={this.props.showImportedEntries}
                  onChange={e =>
                    this.props.dispatch(
                      setShowImportedEntries(e.currentTarget.checked)
                    )
                  }
                />
                Show Imported Entries
              </label>
            </li>
          </ul>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

export const ConnectedImporter = connect((state: AppState, ownProps: {}) => ({
  externalAccountData: externalAccountDataListSelector(state),
  accountByExternalId: accountByExternalIdSelector(state),
  candidateTransactionById: candidateTransactionsSelector(state),
  balanceById: balanceSelector(state),
  accountByImportType: accountByImportTypeSelector(state),
  selectedEntries: importerNsSelector(state).selectedEntries,
  cursorIndex: importerNsSelector(state).cursorIndex,
  cursorAccount: importerNsSelector(state).cursorAccount,
  entryDataById: entryDataByIdSelector(state),
  showImportedEntries: !!importerNsSelector(state).showImportedEntries
}))(Importer);
