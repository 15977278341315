import { Persist } from "./base";
import {
  Split,
  Transaction,
  Account,
  ImportedEntry,
  ExternalAccount
} from "../model/bookkeeping";
import { UUID } from "../lib/core/uuid";
import {
  serializeDateTime,
  unserializeDateTime,
  maybeSerializeDateTime
} from "./serializer";
import { client } from "api/cloud_client";
import {
  addTransactionsRoute,
  addAccountsRoute,
  getAccountDataRoute,
  getImporterDataRoute,
  updateImportedEntrySplitIdsRoute,
  addExternalAccountsAndEntriesRoute,
  updateExternalAccountRoute,
  updateAccountTagsRoute,
  updateTransactionsAndSplitsRoute,
  backupRoute,
  restoreRoute
} from "api/cloud";
import { TransactionMutations } from "data/accounts/types";
import mapValues from "lodash/mapValues";
import { BackupDataSchema } from "./backup";

export class PersistCloud extends Persist {
  addTransactions(transactions: Transaction[], splits: Split[]) {
    return client.request(addTransactionsRoute, {
      transactions: transactions.map(serializeDateTime),
      splits: splits.map(serializeDateTime)
    });
  }

  addAccounts(accounts: Account[]) {
    return client.request(addAccountsRoute, { accounts });
  }

  async getAccountData() {
    const result = await client.request(getAccountDataRoute, {});
    if (result.accounts.length === 0) {
      this.runNewAccountHandler();
    }
    return {
      accounts: result.accounts,
      transactions: result.transactions.map(unserializeDateTime),
      splits: result.splits.map(unserializeDateTime)
    };
  }

  async getImporterData() {
    const result = await client.request(getImporterDataRoute, {});
    return {
      importedEntries: result.importedEntries.map(unserializeDateTime),
      externalAccounts: result.externalAccounts
    };
  }

  updateImportedEntrySplitIds(mapping: Map<UUID, UUID>) {
    const request: { [k: string]: string } = {};
    mapping.forEach((v, k) => (request[k] = v));
    return client.request(updateImportedEntrySplitIdsRoute, request);
  }

  addExternalAccountsAndEntries(
    externalAccount: ExternalAccount | undefined,
    entries: ImportedEntry[]
  ) {
    return client.request(addExternalAccountsAndEntriesRoute, {
      externalAccount,
      entries: entries.map(serializeDateTime)
    });
  }

  updateExternalAccount(
    externalAccount: Partial<ExternalAccount> & Pick<ExternalAccount, "id">
  ) {
    return client.request(updateExternalAccountRoute, { externalAccount });
  }

  updateAccountTags(accountId: UUID, tags: { [tag: string]: string }) {
    return client.request(updateAccountTagsRoute, { accountId, tags });
  }

  async updateTransactionsAndSplits(mutations: TransactionMutations) {
    return client.request(updateTransactionsAndSplitsRoute, {
      newSplits: mutations.newSplits.map(serializeDateTime),
      newTransactions: mutations.newTransactions.map(serializeDateTime),
      dirtySplits: mapValues(mutations.dirtySplits, maybeSerializeDateTime),
      dirtyTransactions: mapValues(
        mutations.dirtyTransactions,
        maybeSerializeDateTime
      )
    });
  }

  backup() {
    return client.request(backupRoute, {});
  }

  restore(data: BackupDataSchema) {
    return client.request(restoreRoute, data);
  }
}

if (process.env.REACT_APP_PERSIST !== "cloud") {
  // @ts-ignore dead code elimination
  PersistCloud = function() {};
}
