import React from "react";
import { connect } from "react-redux";
import {
  subaccountsMapSelector,
  accountByBasicAccountSelector,
  balanceSelector
} from "data/accounts/selectors";
import { AppState } from "data/store";
import { BASIC_ACCOUNT_VALUES } from "data/accounts/tags";
import { negate, ScaledValue, sum, unscaleValue } from "model/scaled_value";
import { AccountValueDisplay } from "./common/account_value";
import { Account } from "model/bookkeeping";
import { Section } from "./common/section_wrapper";

export const BalanceSheetSection: React.FC<{
  subaccountsMap: ReturnType<typeof subaccountsMapSelector>;
  accountByBasicAccount: ReturnType<typeof accountByBasicAccountSelector>;
  balance: ReturnType<typeof balanceSelector>;
}> = React.memo(({ subaccountsMap, accountByBasicAccount, balance }) => {
  const assetsAccount = accountByBasicAccount(BASIC_ACCOUNT_VALUES.ASSETS);
  const liabilitiesAccount = accountByBasicAccount(
    BASIC_ACCOUNT_VALUES.LIABILITIES
  );

  if (!assetsAccount || !liabilitiesAccount) {
    return null;
  }

  const addBalance = (accounts: Account[], sign = (v: ScaledValue) => v) =>
    accounts.map(
      account => [account, sign(balance(account.id))] as [Account, ScaledValue]
    );

  const assetsBalances = addBalance(subaccountsMap(assetsAccount));
  const liabilitiesBalances = addBalance(
    subaccountsMap(liabilitiesAccount),
    negate
  );

  const netWorth = sum(
    ...assetsBalances.map(([_, v]) => v),
    ...liabilitiesBalances.map(([_, v]) => negate(v))
  );

  const allAccounts = [...assetsBalances, ...liabilitiesBalances];

  return (
    <Section title="Balance Sheet">
      <h3>Net Worth</h3>
      <h2>${unscaleValue(netWorth)}</h2>
      <AccountValueDisplay values={allAccounts} sumChildren={true} />
    </Section>
  );
});

export const ConnectedBalanceSheetSection = connect((state: AppState) => ({
  subaccountsMap: subaccountsMapSelector(state),
  accountByBasicAccount: accountByBasicAccountSelector(state),
  balance: balanceSelector(state)
}))(BalanceSheetSection);
