export const AUTH_NS = "AUTH";

export type BMUser = {
  name: string | null;
};

export enum AuthStatus {
  UNKNOWN = "UNKNOWN",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT"
}

export interface AuthState {
  status: AuthStatus;
  requireLogin: boolean;
}
