import { createSelector } from "reselect";
import { AppState } from "../store";
import { ACCOUNTS_NS } from "./types";
import {
  EXTERNAL_ACCOUNT_IDS,
  EXTERNAL_ACCOUNT_TYPE,
  ENTRY_CATEGORY,
  OPENING_BALANCE,
  BASIC_ACCOUNT_VALUES,
  BASIC_ACCOUNT,
  TYPE,
  TYPE_VALUES
} from "./tags";
import { Account, ExternalAccountType } from "../../model/bookkeeping";
import { UUID } from "../../lib/core/uuid";
import memoize from "lodash/memoize";
import { EntryCategory } from "../../static_data/categories";
import groupBy from "lodash/groupBy";
import {
  isSubaccount,
  treeify,
  breakAccountName,
  joinAccountName
} from "lib/accounts/hierarchy";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";
import { ScaledValue, sum } from "model/scaled_value";
import { dateToNumber } from "lib/datetime/date";

export const accountsNsSelector = (state: AppState) => state[ACCOUNTS_NS];

export const accountDataSelector = (state: AppState) =>
  accountsNsSelector(state).accountData;

export const accountsReadySelector = createSelector(
  accountsNsSelector,
  state => !!state.ready
);

export const accountsDataListSelector = createSelector(
  accountsNsSelector,
  state => Object.values(state.accountData)
);

export const accountsListSelector = createSelector(
  accountsDataListSelector,
  accounts => accounts.map(account => account.account)
);

export const sortedAccountsListSelector = createSelector(
  accountsListSelector,
  accounts => sortBy(accounts, account => account.name)
);

export const accountsMapSelector = createSelector(
  accountsDataListSelector,
  accounts =>
    new Map(accounts.map(account => [account.account.id, account.account]))
);

export const accountByExternalIdSelector = createSelector(
  accountDataSelector,
  data => {
    const map = new Map<string, Account>();
    for (const account of Object.values(data)) {
      if (account.account.tags[EXTERNAL_ACCOUNT_IDS]) {
        for (const externalId of account.account.tags[
          EXTERNAL_ACCOUNT_IDS
        ].split(",")) {
          if (externalId.length) {
            map.set(externalId, account.account);
          }
        }
      }
    }

    return (externalId: string) => {
      return map.get(externalId);
    };
  }
);

const accountByTagValueSelectorFactory = <T>(tag: string) =>
  createSelector(
    accountDataSelector,
    data => {
      const map = new Map<string, Account>();
      for (const account of Object.values(data)) {
        if (account.account.tags[tag] != null) {
          map.set(account.account.tags[tag], account.account);
        }
      }

      return (tagValue: T) => {
        return map.get("" + tagValue);
      };
    }
  );

export const accountByEntryCategorySelector = accountByTagValueSelectorFactory<
  EntryCategory
>(ENTRY_CATEGORY);

export const accountByBasicAccountSelector = accountByTagValueSelectorFactory<
  BASIC_ACCOUNT_VALUES
>(BASIC_ACCOUNT);

export const accountsByTagValueSelector = createSelector(
  accountDataSelector,
  data => (tag: string, func: (v: string | undefined) => boolean) => {
    return Object.values(data)
      .filter(account => func(account.account.tags[tag]))
      .map(account => account.account);
  }
);

export const subaccountsMapSelector = createSelector(
  sortedAccountsListSelector,
  accounts => {
    const map = new Map<Account, Account[]>();
    for (let i = 0; i < accounts.length - 1; i++) {
      for (let j = i + 1; j < accounts.length; j++) {
        if (isSubaccount(accounts[i].name, accounts[j].name)) {
          let children = map.get(accounts[i]);
          if (!children) {
            children = [];
            map.set(accounts[i], children);
          }
          children.push(accounts[j]);
        } else {
          break;
        }
      }
    }
    return (account: Account) => map.get(account) || [];
  }
);

export const openingBalanceAccountSelector = createSelector(
  accountDataSelector,
  data => {
    for (const account of Object.values(data)) {
      if (account.account.tags[OPENING_BALANCE] != null) {
        return account.account;
      }
    }
    return undefined;
  }
);

export const splitsByIdSelector = createSelector(
  accountsNsSelector,
  state => state.splits
);

export const transactionByIdSelector = createSelector(
  accountsNsSelector,
  state => state.transactions
);

export const splitsSelector = createSelector(
  splitsByIdSelector,
  transactionByIdSelector,
  (splits, txById) =>
    Object.values(splits).sort(
      (sp1, sp2) =>
        txById[sp2.transactionId].datetime.datetime.toMillis() -
        txById[sp1.transactionId].datetime.datetime.toMillis()
    )
);

export const splitsByTransactionSelector = createSelector(
  splitsSelector,
  splits => groupBy(splits, s => s.transactionId)
);

export const splitsByAccountIdFuncSelector = createSelector(
  splitsSelector,
  splits => {
    const grouped = groupBy(splits, sp => sp.accountId);
    return (id: UUID) => grouped[id] || [];
  }
);

export const balanceListFuncSelector = createSelector(
  splitsByAccountIdFuncSelector,
  transactionByIdSelector,
  (splitFunc, txById) => {
    const map = new Map<UUID, [number, ScaledValue][]>();

    return (accountId: UUID) => {
      let haystack: [number, ScaledValue][];
      if (!map.has(accountId)) {
        const splits = splitFunc(accountId);
        haystack = [];
        for (let i = splits.length - 1; i >= 0; i--) {
          const split = splits[i];
          const dateNum = dateToNumber(
            txById[split.transactionId].datetime.datetime
          );
          if (
            haystack.length > 0 &&
            haystack[haystack.length - 1][0] === dateNum
          ) {
            haystack[haystack.length - 1][1] = (haystack[
              haystack.length - 1
            ][1] + split.valueScaled) as ScaledValue;
          } else {
            if (haystack.length === 0) {
              haystack.push([dateNum, split.valueScaled]);
            } else {
              haystack.push([
                dateNum,
                (haystack[haystack.length - 1][1] +
                  split.valueScaled) as ScaledValue
              ]);
            }
          }
        }

        map.set(accountId, haystack);
      } else {
        haystack = map.get(accountId)!;
      }
      return haystack;
    };
  }
);

export function findDateIndex(
  haystack: [number, ScaledValue][],
  dateNum: number
) {
  let low = 0,
    high = haystack.length - 1;
  while (low < high) {
    const mid = (((low + high) / 2) | 0) + 1;
    if (haystack[mid][0] > dateNum) {
      high = mid - 1;
    } else {
      low = mid;
    }
  }

  if (haystack[low][0] > dateNum) {
    return -1;
  }
  return low;
}

export const balanceAtTransactionDateFuncSelector = createSelector(
  balanceListFuncSelector,
  balanceListFunc => {
    return (accountId: UUID, date: DateTime) => {
      const haystack = balanceListFunc(accountId);
      const dateNum = dateToNumber(date);
      const index = findDateIndex(haystack, dateNum);

      if (index < 0) {
        return undefined;
      }
      return haystack[index][1];
    };
  }
);

export const transactionsSelector = createSelector(
  transactionByIdSelector,
  transactions => Object.values(transactions)
);

export const reverseSortedTransactionsSelector = createSelector(
  transactionsSelector,
  transactions => sortBy(transactions, k => -k.datetime.datetime.toMillis())
);

export const balanceSelector = createSelector(
  splitsByAccountIdFuncSelector,
  splitsByAccountId =>
    memoize((id: UUID) => sum(...splitsByAccountId(id).map(s => s.valueScaled)))
);

export const splitIdsSetSelector = createSelector(
  splitsByAccountIdFuncSelector,
  splitsByAccountId =>
    memoize(id => new Set<UUID>(splitsByAccountId(id).map(s => s.id)))
);

export const accountByImportTypeSelector = createSelector(
  accountsListSelector,
  accounts => (type: ExternalAccountType) =>
    accounts.find(account => account.tags[EXTERNAL_ACCOUNT_TYPE] === type)
);

export interface AccountTree {
  name: string;
  fullName: string;
  account?: Account;
  children: AccountTree[];
}

export const accountForestSelector = createSelector(
  accountsListSelector,
  accounts =>
    treeify<AccountTree, Account>(
      accounts,
      account => breakAccountName(account.name),
      t => t.name,
      t => t.children,
      (account, soFar) => ({
        name: soFar[soFar.length - 1],
        fullName: joinAccountName(soFar),
        children: []
      }),
      (account, tree) => {
        tree.account = account;
      }
    )
);

export const accountIsDebitFuncSelector = createSelector(
  accountsByTagValueSelector,
  subaccountsMapSelector,
  (accountsByTagValueFunc, subaccountsMap) => {
    const debitAccounts = new Set<UUID>();

    for (const debitAccount of accountsByTagValueFunc(
      TYPE,
      tag => tag === TYPE_VALUES.DEBIT
    )) {
      debitAccounts.add(debitAccount.id);
      for (const subaccount of subaccountsMap(debitAccount)) {
        debitAccounts.add(subaccount.id);
      }
    }

    return (accountId: UUID) => debitAccounts.has(accountId);
  }
);
