import React, { PureComponent } from "react";
import "react-vis/dist/style.css";
import {
  FlexibleWidthXYPlot,
  LineMarkSeries,
  LineSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  Highlight
} from "react-vis";
import { Split, Transaction } from "../../model/bookkeeping";
import { connect } from "react-redux";
import {
  transactionByIdSelector,
  accountsMapSelector,
  splitsByTransactionSelector,
  accountIsDebitFuncSelector,
  balanceListFuncSelector,
  findDateIndex
} from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import {
  filteredSplitsSelector,
  selectedAccountIdSelector,
  filtersSelector
} from "../../data/ledgers/selectors";
import { unscaleValue, ScaledValue } from "../../model/scaled_value";
import { DateTime, DurationUnit } from "luxon";
import groupBy from "lodash/groupBy";
import { dateToNumber, numberToDate } from "lib/datetime/date";
import { UUID } from "lib/core/uuid";
import regressionLib from "regression";
import pageCss from "components/styles/page.module.css";
import { hsvToHex } from "utils/color";
import { Filter } from "data/ledgers/types";
import { setFilterDateRange } from "data/ledgers/actions";

interface ChartingOptions {
  data: "balance" | "delta" | "cumulative";
  accounts: "sum" | "separate";
  grouping: DurationUnit;
  origin: "zero" | "auto";
  signConventionDebit: "positive" | "negative";
  signConventionCredit: "positive" | "negative";
  regression?: "linear" | "power" | "exponential" | "logarithmic";
  extrapolateDays: number;
}

export const DEFAULT_OPTIONS: ChartingOptions = {
  data: "delta",
  accounts: "separate",
  grouping: "month",
  origin: "zero",
  signConventionDebit: "positive",
  signConventionCredit: "positive",
  extrapolateDays: 0
};

const ChartingSettings: React.FC<{
  options: ChartingOptions;
  onChange(newOptions: ChartingOptions): void;
}> = ({ options, onChange }) => {
  return (
    <ul>
      <li>
        <label>
          Plot:
          <select
            onChange={e =>
              onChange({
                ...options,
                data: e.currentTarget.value as ChartingOptions["data"]
              })
            }
            value={options.data}
          >
            <option value="balance">Balance</option>
            <option value="delta">Change</option>
            <option value="cumulative">Cumulative Change</option>
          </select>
        </label>
      </li>
      {options.data === "delta" && (
        <li>
          <label>
            Group By
            <select
              onChange={e =>
                onChange({
                  ...options,
                  grouping: e.currentTarget.value as ChartingOptions["grouping"]
                })
              }
              value={options.grouping}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="quarter">Quarter</option>
              <option value="year">Year</option>
            </select>
          </label>
        </li>
      )}

      <li>
        <label>
          Origin:
          <BindedInput
            tagName="select"
            state={options}
            bindTo="origin"
            onChange={onChange}
          >
            <option value="zero">Zero</option>
            <option value="auto">Auto</option>
          </BindedInput>
        </label>
      </li>
      <li>
        <label>
          Multiple Accounts:
          <BindedInput
            tagName="select"
            state={options}
            bindTo="accounts"
            onChange={onChange}
          >
            <option value="separate">Separate</option>
            <option value="sum">Sum</option>
          </BindedInput>
        </label>
      </li>
      <li>
        Sign conventions: Debit:
        <BindedInput
          tagName="select"
          state={options}
          bindTo="signConventionDebit"
          onChange={onChange}
        >
          <option value="positive">Positive</option>
          <option value="negative">Negative</option>
        </BindedInput>
        Credit:
        <BindedInput
          tagName="select"
          state={options}
          bindTo="signConventionCredit"
          onChange={onChange}
        >
          <option value="positive">Positive</option>
          <option value="negative">Negative</option>
        </BindedInput>
      </li>
      <li>
        <label>
          Regression:
          <BindedInput
            tagName="select"
            state={options}
            bindTo="regression"
            onChange={onChange}
            valueMap={v =>
              v === "none" ? undefined : (v as typeof options["regression"])
            }
            valueUnmap={v => v || "none"}
          >
            <option value="none">None</option>
            <option value="linear">Linear</option>
            <option value="power">Power</option>
            <option value="exponential">Exponential</option>
            <option value="logarithmic">Logarithmic</option>
          </BindedInput>
        </label>
      </li>
      {options.regression && (
        <li>
          <label>
            Projection:
            <BindedInput
              tagName="select"
              state={options}
              bindTo="extrapolateDays"
              onChange={onChange}
              valueMap={v => +v}
              valueUnmap={v => "" + v}
            >
              <option value="0">None</option>
              <option value="30">30 days</option>
              <option value="60">60 days</option>
              <option value="90">90 days</option>
              <option value="180">180 days</option>
              <option value="365">365 days</option>
            </BindedInput>
          </label>
        </li>
      )}
    </ul>
  );
};

type AttrType<T> = T extends React.DetailedHTMLFactory<infer U, any> ? U : {};

class BindedInput<
  T extends keyof React.ReactHTML,
  S,
  R extends keyof S
> extends PureComponent<{
  tagName: T;
  state: S;
  bindTo: R;
  onChange(s: S): void;
  props?: AttrType<React.ReactHTML[T]>;
  valueMap?: (s: string) => S[R];
  valueUnmap?: (v: S[R]) => string;
}> {
  render() {
    const {
      tagName,
      state,
      bindTo,
      onChange,
      children,
      valueMap,
      valueUnmap,
      props
    } = this.props;

    return React.createElement(
      tagName,
      {
        value: valueUnmap ? valueUnmap(state[bindTo]) : "" + state[bindTo],
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            ...state,
            [bindTo]: valueMap
              ? valueMap(e.currentTarget.value)
              : e.currentTarget.value
          }),
        ...props
      } as any,
      children
    );
  }
}

type Props = {
  splits: Split[];
  transactions: {
    [id: string]: Transaction;
  };
  accountsMap: ReturnType<typeof accountsMapSelector>;
  splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
  balanceListFunc: ReturnType<typeof balanceListFuncSelector>;
  accountIsDebitFunc: ReturnType<typeof accountIsDebitFuncSelector>;
  selectedAccountId: ReturnType<typeof selectedAccountIdSelector>;
  filters: ReturnType<typeof filtersSelector>;

  setFilterDateRange: typeof setFilterDateRange;
};

export function signForAccount(
  isDebit: boolean,
  options: Pick<ChartingOptions, "signConventionDebit" | "signConventionCredit">
) {
  if (isDebit) {
    return options.signConventionDebit === "negative" ? -1 : 1;
  } else {
    return options.signConventionCredit === "positive" ? -1 : 1;
  }
}

type SplitAndTxDate = [Split, DateTime];

export function deltaDataSet(
  splits: SplitAndTxDate[],
  accountIsDebit: (id: UUID) => boolean,
  options: Pick<
    ChartingOptions,
    "signConventionDebit" | "signConventionCredit" | "accounts" | "grouping"
  >
) {
  const groupedSplits =
    options.accounts === "separate"
      ? Object.values(groupBy(splits, s => s[0].accountId))
      : [splits];

  return groupedSplits.map(splits => {
    let date: DateTime | undefined = undefined;
    const data: { x: DateTime; y: number }[] = [];
    for (let i = splits.length - 1; i >= 0; i--) {
      const [split, txDateTime] = splits[i];
      if (txDateTime.toMillis() <= 24 * 3600 * 1000) {
        // Ignore opening balance
        continue;
      }
      const dt = txDateTime.startOf(options.grouping);

      const value =
        signForAccount(accountIsDebit(split.accountId), options) *
        unscaleValue(split.valueScaled);

      if (
        date &&
        dt.year === date.year &&
        dt.month === date.month &&
        dt.day === date.day
      ) {
        data[data.length - 1].y += value;
      } else {
        date = DateTime.local(dt.year, dt.month, dt.day);
        data.push({ x: date, y: value });
      }
    }

    return data;
  });
}

export function balanceDataSet(
  balanceListFunc: (accountId: UUID) => [number, ScaledValue][],
  selectedAccountId: UUID[],
  filters: Filter[] | undefined,
  accountIsDebit: (id: UUID) => boolean,
  options: Pick<
    ChartingOptions,
    "signConventionCredit" | "signConventionDebit" | "accounts"
  >
) {
  let minDateNum = Infinity,
    maxDateNum = -Infinity;
  if (filters) {
    for (const filter of filters) {
      if (filter.dateRange) {
        const startNum = dateToNumber(filter.dateRange.startTime);
        const endNum = dateToNumber(filter.dateRange.endTime);
        if (startNum < minDateNum) {
          minDateNum = startNum;
        }
        if (endNum > maxDateNum) {
          maxDateNum = endNum;
        }
      }
    }
  }

  if (minDateNum > maxDateNum) {
    minDateNum = -Infinity;
    maxDateNum = Infinity;
  }

  let dataSet = selectedAccountId
    .map(accountId => {
      const data: { x: DateTime; y: number }[] = [];
      const balances = balanceListFunc(accountId);

      if (balances.length > 0) {
        const sign = signForAccount(accountIsDebit(accountId), options);
        let startIndex = findDateIndex(balances, minDateNum);
        if (startIndex < 0) startIndex = 0;
        let isFirst = true;
        while (
          startIndex < balances.length &&
          balances[startIndex][0] < maxDateNum
        ) {
          data.push({
            x: numberToDate(
              isFirst && minDateNum > balances[startIndex][0]
                ? minDateNum
                : balances[startIndex][0]
            ),
            y: unscaleValue(balances[startIndex][1]) * sign
          });
          isFirst = false;
          startIndex++;
        }
      }

      return data;
    })
    .filter(l => l.length > 0);

  if (options.accounts === "sum") {
    const series: { x: DateTime; y: number }[] = [];
    let lastDateTime = undefined;
    let totalNum = 0;
    const indices: number[] = [];
    const balances: number[] = [];

    for (let i = 0; i < dataSet.length; i++) {
      indices.push(0);
      balances.push(0);
      totalNum += dataSet[i].length;
    }

    for (let _ = 0; _ < totalNum; _++) {
      let min = Number.MAX_SAFE_INTEGER,
        minIndex = -1;
      for (let i = 0; i < indices.length; i++) {
        if (dataSet[i][indices[i]]) {
          const val = dateToNumber(dataSet[i][indices[i]].x);
          if (val < min) {
            min = val;
            minIndex = i;
          }
        }
      }

      if (!lastDateTime) {
        lastDateTime = dataSet[minIndex][indices[minIndex]].x;
      }

      if (!dataSet[minIndex][indices[minIndex]].x.equals(lastDateTime)) {
        series.push({
          x: lastDateTime,
          y: balances.reduce((a, b) => a + b)
        });
        lastDateTime = dataSet[minIndex][indices[minIndex]].x;
      }

      balances[minIndex] = dataSet[minIndex][indices[minIndex]].y;
      indices[minIndex]++;
    }

    if (lastDateTime) {
      series.push({
        x: lastDateTime,
        y: balances.reduce((a, b) => a + b)
      });
    }

    if (series.length > 0) {
      dataSet = [series];
    }
  }

  for (const series of dataSet) {
    while (series.length > 0 && series[0].x.toMillis() <= 24 * 3600 * 1000) {
      series.shift();
    }
  }
  return dataSet.filter(series => series.length > 0);
}

function cumulativeDataSet(
  splits: SplitAndTxDate[],
  accountIsDebit: (id: UUID) => boolean,
  options: Pick<
    ChartingOptions,
    "signConventionCredit" | "signConventionDebit" | "accounts"
  >
) {
  const groupedSplits =
    options.accounts === "separate"
      ? Object.values(groupBy(splits, s => s[0].accountId))
      : [splits];

  return groupedSplits.map(splits => {
    let date: DateTime | undefined = undefined;
    const data: { x: DateTime; y: number }[] = [];
    for (let i = splits.length - 1; i >= 0; i--) {
      const [split, txDateTime] = splits[i];
      if (txDateTime.toMillis() <= 24 * 3600 * 1000) {
        // Ignore opening balance
        continue;
      }
      const dt = DateTime.local(
        txDateTime.year,
        txDateTime.month,
        txDateTime.day
      );

      const value =
        signForAccount(accountIsDebit(split.accountId), options) *
        unscaleValue(split.valueScaled);

      if (
        date &&
        dt.year === date.year &&
        dt.month === date.month &&
        dt.day === date.day
      ) {
        data[data.length - 1].y += value;
      } else {
        date = DateTime.local(dt.year, dt.month, dt.day);
        data.push({
          x: date,
          y: (data.length === 0 ? 0 : data[data.length - 1].y) + value
        });
      }
    }

    return data;
  });
}

export class Chart extends PureComponent<Props, { options: ChartingOptions }> {
  state = { options: DEFAULT_OPTIONS };

  onOptionChange = (options: ChartingOptions) => {
    this.setState({ options });
  };

  regression(
    series: { x: DateTime; y: number }[]
  ): { x: DateTime; y: number }[] | undefined {
    const NUM_POINTS = 100;
    const { regression, extrapolateDays } = this.state.options;
    if (!regression) {
      return undefined;
    }
    if (series.length > 1) {
      const dX = series[0].x.toMillis() - Math.E;
      const dY = Math.min(...series.map(dp => dp.y)) - Math.E;
      const datapoints = series.map(
        dp =>
          [(dp.x.toMillis() - dX) / 1000 / 3600, dp.y - dY] as [number, number]
      );

      const func = regressionLib[regression](datapoints, { precision: 15 });

      const lastX = datapoints[datapoints.length - 1][0] + extrapolateDays * 24;
      const firstX = datapoints[0][0];
      const interval = (lastX - firstX) / NUM_POINTS;

      const result = [];
      for (let x = firstX; x < lastX; x += interval) {
        const [, y] = func.predict(x);
        result.push({
          x: DateTime.fromMillis(dX + x * 1000 * 3600),
          y: y + dY
        });
      }
      return result;
    }
    return undefined;
  }

  splitsAndTxDate(): SplitAndTxDate[] {
    return this.props.splits.map(s => [
      s,
      this.props.transactions[s.transactionId].datetime.datetime
    ]);
  }

  render() {
    const { options } = this.state;
    const {
      splits,
      balanceListFunc,
      selectedAccountId,
      filters,
      accountIsDebitFunc,
      setFilterDateRange
    } = this.props;

    let dataSet: { x: DateTime; y: number }[][] = [];
    switch (options.data) {
      case "balance":
        dataSet = balanceDataSet(
          balanceListFunc,
          selectedAccountId,
          filters,
          accountIsDebitFunc,
          options
        );
        break;
      case "cumulative":
        dataSet = cumulativeDataSet(
          this.splitsAndTxDate(),
          accountIsDebitFunc,
          options
        );
        break;
      case "delta":
        dataSet = deltaDataSet(
          this.splitsAndTxDate(),
          accountIsDebitFunc,
          options
        );
        break;
    }

    const dataSetColor = dataSet.map((_, i) =>
      hsvToHex(i / dataSet.length, 0.46, 0.53)
    );

    const regDataSet = dataSet.map(this.regression, this);
    const regDataSetColor = regDataSet.map((_, i) =>
      hsvToHex(i / dataSet.length, 0.26, 0.78)
    );

    let minValue = +Infinity;
    let maxValue = -Infinity;

    for (const ds of [dataSet, regDataSet]) {
      for (const series of ds) {
        if (series) {
          for (const point of series) {
            if (point.y < minValue) {
              minValue = point.y;
            }
            if (point.y > maxValue) {
              maxValue = point.y;
            }
          }
        }
      }
    }

    let domain;
    if (options.origin === "auto" || minValue * maxValue <= 0) {
      domain = [minValue, maxValue];
    } else {
      if (maxValue > 0) {
        domain = [0, maxValue];
      } else {
        domain = [minValue, 0];
      }
    }

    return (
      <div className={pageCss.block}>
        {splits.length > 0 ? (
          <FlexibleWidthXYPlot height={300} yDomain={domain}>
            <XAxis
              tickFormat={(val: number) => {
                return DateTime.fromMillis(val).toLocaleString(
                  DateTime.DATE_SHORT
                );
              }}
              tickTotal={10}
            />
            <YAxis
              tickFormat={(val: number) => {
                let suffix = "";
                if (Math.abs(val) >= 1000) {
                  val /= 1000;
                  suffix = "K";
                }
                if (Math.abs(val) >= 1000) {
                  val /= 1000;
                  suffix = "M";
                }
                if (Math.abs(val) >= 1000) {
                  val /= 1000;
                  suffix = "B";
                }
                if (Math.abs(val) >= 1000) {
                  val /= 1000;
                  suffix = "T";
                }

                return +val.toPrecision(3) + suffix;
              }}
              width={50}
            />
            <VerticalGridLines />
            <HorizontalGridLines />
            {dataSet.map((data, index) => (
              <LineMarkSeries
                key={`line-${index}`}
                data={data}
                color={dataSetColor[index]}
              />
            ))}
            {regDataSet.map((regression, index) => {
              if (!regression) {
                return null;
              }

              return (
                <LineSeries
                  key={`reg-${index}`}
                  data={regression}
                  color={regDataSetColor[index]}
                  style={{
                    "stroke-dasharray": 5
                  }}
                />
              );
            })}
            <Highlight
              enableX={true}
              enableY={false}
              onBrushEnd={(area: { left: number; right: number }) =>
                setFilterDateRange(
                  DateTime.fromMillis(area.left),
                  DateTime.fromMillis(area.right)
                )
              }
            />
          </FlexibleWidthXYPlot>
        ) : (
          <div>No data to display</div>
        )}

        <ChartingSettings
          options={this.state.options}
          onChange={this.onOptionChange}
        />
      </div>
    );
  }
}

export const ConnectedChart = connect(
  (state: AppState) => ({
    splits: filteredSplitsSelector(state),
    transactions: transactionByIdSelector(state),
    accountsMap: accountsMapSelector(state),
    splitsByTransaction: splitsByTransactionSelector(state),
    balanceListFunc: balanceListFuncSelector(state),
    accountIsDebitFunc: accountIsDebitFuncSelector(state),
    selectedAccountId: selectedAccountIdSelector(state),
    filters: filtersSelector(state)
  }),
  {
    setFilterDateRange
  }
)(Chart);
