import React from "react";

import {
  unscaleValue,
  ScaledValue,
  scaleValue
} from "../../model/scaled_value";

interface OwnProps {
  value?: ScaledValue;
  onChange?(numer: ScaledValue): void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ValueScaledInput: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof OwnProps> & OwnProps
> = props => {
  const { inputRef, value, onChange, ...rest } = props;
  return (
    <input
      ref={props.inputRef}
      type="number"
      min={0}
      step={0.01}
      {...rest}
      value={props.value ? unscaleValue(+props.value as ScaledValue) : ""}
      defaultValue={
        props.defaultValue &&
        "" + unscaleValue(+props.defaultValue as ScaledValue)
      }
      onChange={e =>
        props.onChange
          ? props.onChange(scaleValue(e.currentTarget.value))
          : undefined
      }
    />
  );
};
