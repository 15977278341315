import React, { PureComponent } from "react";
import { UUID, newUuid } from "../../lib/core/uuid";
import { ScaledValue, negate } from "../../model/scaled_value";
import { ConnectedAccountSelector } from "../common/account";
import { ValueScaledInput } from "../common/value_scaled_input";
import { BMDateTime } from "../../lib/datetime/types";
import { DateTime } from "luxon";
import { Transaction, Split } from "model/bookkeeping";
import css from "./transaction_creator.module.css";
import { ReactComponent as CloseIcon } from "../../assets/font-awesome-solid/times-circle.svg";
import { accountIsDebitFuncSelector } from "data/accounts/selectors";
import { AppState } from "data/store";
import { connect } from "react-redux";

interface ProtoSplit {
  id: UUID;
  accountId: UUID;
  valueScaled: ScaledValue;
  memo?: string;
  disabled?: boolean;
  datetime?: BMDateTime;
}

type TransactionCreatorProps = {
  splits?: ProtoSplit[];
  memo?: string;
  onSave?(transaction: Transaction, splits: Split[]): void;
  accountIsDebitFunc: ReturnType<typeof accountIsDebitFuncSelector>;
};

interface TransactionCreatorState {
  memo: string;
  splits: ProtoSplit[];
  datetime?: BMDateTime;
}

type RowProps = {
  id: UUID;
  accountId?: UUID;
  accountType?: "debit" | "credit";
  valueScaled?: ScaledValue;
  disabled?: boolean;
  hideDelete?: boolean;
  onValueChange?(id: UUID, valueScaled: ScaledValue): void;
  onAccountChange?(id: UUID, accountId: UUID): void;
  onDelete?(id: UUID): void;
};

class Row extends PureComponent<RowProps> {
  debitRef = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: RowProps) {
    if (this.props.accountId && this.props.accountId !== prevProps.accountId) {
      if (this.debitRef.current) this.debitRef.current.focus();
    }
  }

  render() {
    const props = this.props;

    return (
      <tr>
        <td>
          <ConnectedAccountSelector
            value={props.accountId}
            readOnly={props.disabled}
            onChange={accountId =>
              props.onAccountChange &&
              props.onAccountChange(props.id, accountId)
            }
          />
        </td>
        <td className={css.amountCell}>
          <ValueScaledInput
            inputRef={this.debitRef}
            className={
              props.accountType &&
              (props.accountType === "debit"
                ? css.increaseInput
                : css.decreaseInput)
            }
            readOnly={props.disabled}
            disabled={!props.accountId}
            value={
              props.valueScaled !== undefined && props.valueScaled > 0
                ? props.valueScaled
                : undefined
            }
            onChange={newValue =>
              props.onValueChange && props.onValueChange(props.id, newValue)
            }
          />
        </td>
        <td className={css.amountCell}>
          <ValueScaledInput
            className={
              props.accountType
                ? props.accountType === "credit"
                  ? css.increaseInput
                  : css.decreaseInput
                : css.input
            }
            readOnly={props.disabled}
            disabled={!props.accountId}
            value={
              props.valueScaled !== undefined && props.valueScaled < 0
                ? negate(props.valueScaled)
                : undefined
            }
            onChange={newValue =>
              props.onValueChange &&
              props.onValueChange(props.id, negate(newValue))
            }
          />
        </td>
        <td>
          {!props.hideDelete && (
            <button
              disabled={props.disabled}
              onClick={() => props.onDelete && props.onDelete(props.id)}
              className={css.deleteButton}
            >
              <CloseIcon width={16} height={16} />
            </button>
          )}
        </td>
      </tr>
    );
  }
}

export class TransactionCreator extends PureComponent<
  TransactionCreatorProps,
  TransactionCreatorState
> {
  constructor(props: TransactionCreatorProps) {
    super(props);
    this.state = {
      memo: props.memo || "",
      splits: props.splits || [],
      datetime: (props.splits || []).map(sp => sp.datetime).find(dt => !!dt)
    };
  }

  componentDidUpdate(prevProps: TransactionCreatorProps) {
    if (prevProps.splits !== this.props.splits) {
      this.setState({
        splits: this.props.splits || []
      });
    }
    if (prevProps.memo !== this.props.memo) {
      this.setState({
        memo: this.props.memo || ""
      });
    }
  }

  onMemoChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    this.setState({ memo: e.currentTarget.value });
  };

  balance = () =>
    this.state.splits.reduce(
      (acc, cur) => (acc + cur.valueScaled) as ScaledValue,
      0 as ScaledValue
    );

  private updateFieldById = (id: UUID, updated: Partial<ProtoSplit>) => {
    this.setState({
      splits: this.state.splits.map(s =>
        s.id === id ? { ...s, ...updated } : s
      )
    });
  };

  onValueChange = (id: UUID, valueScaled: ScaledValue) => {
    this.updateFieldById(id, { valueScaled });
  };

  onAccountChange = (id: UUID, accountId: UUID) => {
    this.updateFieldById(id, { accountId });
  };

  onDelete = (id: UUID) => {
    this.setState(state => ({
      splits: state.splits.filter(sp => sp.id !== id)
    }));
  };

  onAddEntry = (id: UUID, accountId: UUID, valueScaled: ScaledValue) => {
    this.setState(state => ({
      splits: [
        ...state.splits,
        {
          id,
          accountId,
          valueScaled
        }
      ]
    }));
  };

  datetimeValue() {
    if (!this.state.datetime) {
      return undefined;
    }
    return this.state.datetime.datetime.toISODate();
  }

  onDateChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    this.setState({
      datetime: {
        datetime: DateTime.fromISO(e.currentTarget.value),
        hasTime: false
      }
    });
  };

  onSave = (memo: string, datetime: BMDateTime, splits: ProtoSplit[]) => {
    if (this.props.onSave) {
      const transactionId = newUuid();
      this.props.onSave(
        {
          id: transactionId,
          memo,
          tags: {},
          datetime
        },
        splits.map(ps => ({
          ...ps,
          tags: {},
          transactionId,
          datetime: ps.datetime || datetime
        }))
      );
    }
  };

  render() {
    const balance = this.balance();
    const newId = newUuid();

    const rows = this.state.splits.map(protoSplit => (
      <Row
        key={protoSplit.id}
        id={protoSplit.id}
        accountId={protoSplit.accountId}
        accountType={
          protoSplit.accountId
            ? this.props.accountIsDebitFunc(protoSplit.accountId)
              ? "debit"
              : "credit"
            : undefined
        }
        valueScaled={protoSplit.valueScaled}
        disabled={!!protoSplit.disabled}
        onAccountChange={this.onAccountChange}
        onValueChange={this.onValueChange}
        onDelete={this.onDelete}
      />
    ));

    rows.push(
      <Row
        key={newId}
        id={newId}
        valueScaled={negate(balance)}
        onAccountChange={(id, accountId) =>
          this.onAddEntry(id, accountId, negate(balance))
        }
        hideDelete={true}
      />
    );

    return (
      <div>
        <input
          placeholder="Memo"
          value={this.state.memo}
          onChange={this.onMemoChange}
        />
        <input
          type="date"
          value={this.datetimeValue()}
          onChange={this.onDateChange}
        />
        <table>
          <thead>
            <tr>
              <td>Account</td>
              <td>Dr</td>
              <td>Cr</td>
              <td />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div>
          <button
            disabled={
              balance !== 0 ||
              !this.state.datetime ||
              this.state.memo.length === 0
            }
            onClick={() =>
              this.onSave(
                this.state.memo,
                this.state.datetime!,
                this.state.splits
              )
            }
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export const ConnectedTransactionCreator = connect((state: AppState) => ({
  accountIsDebitFunc: accountIsDebitFuncSelector(state)
}))(TransactionCreator);
