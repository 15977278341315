import { ImporterState, IMPORTER_NS } from "./importer/types";
import { AccountsState, ACCOUNTS_NS } from "./accounts/types";
import { createStore, applyMiddleware, compose } from "redux";
import { IMPORTER_DEFAULT_STATE } from "./importer/default_state";
import { ACCOUNTS_DEFAULT_STATE } from "./accounts/default_state";
import thunk, { ThunkDispatch } from "redux-thunk";
import { importerReducer } from "./importer/reducers";
import { reduceReducers } from "./utils";
import { accountsReducer } from "./accounts/reducers";
import { OobeState, OOBE_NS } from "./oobe/types";
import { oobeReducer } from "./oobe/reducers";
import { LEDGERS_NS, LedgersState } from "./ledgers/types";
import { ledgersReducer } from "./ledgers/reducers";
import { AUTH_NS, AuthState } from "./auth/types";
import { authReducer } from "./auth/reducers";

export interface AppState {
  [IMPORTER_NS]: ImporterState;
  [ACCOUNTS_NS]: AccountsState;
  [OOBE_NS]: OobeState;
  [LEDGERS_NS]: LedgersState;
  [AUTH_NS]: AuthState;
}

export type Dispatch = ThunkDispatch<AppState, {}, any>;

const composeEnhancers =
  process.env.NODE_ENV === "production"
    ? compose
    : (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reduceReducers<AppState, any>(
    {
      [IMPORTER_NS]: IMPORTER_DEFAULT_STATE,
      [ACCOUNTS_NS]: ACCOUNTS_DEFAULT_STATE
    } as AppState,
    ledgersReducer,
    importerReducer,
    accountsReducer,
    oobeReducer,
    authReducer
  ),
  (window as any).STATE,

  composeEnhancers(applyMiddleware(thunk))
);

(window as any).STATE = undefined;
