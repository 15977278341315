import React, { PureComponent } from "react";
import cx from "classnames";
import { Transactions } from "./transactions";
import { ConnectedTransactionCreator } from "components/common/transaction_creator";
import { Dispatch, AppState } from "data/store";
import { addTransactions, mutate } from "data/accounts/actions";
import { connect } from "react-redux";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import css from "./main.module.css";
import { TransactionsEditor } from "./transactions_editor";
import { Transaction, Split } from "model/bookkeeping";
import {
  accountsMapSelector,
  reverseSortedTransactionsSelector,
  splitsByTransactionSelector
} from "data/accounts/selectors";

enum SidebarMode {
  ADD_TRANSACTION = "ADD_TRANSACTION",
  EDIT_TRANSACTION = "EDIT_TRANSACTION"
}

type State = {
  sidebarMode?: SidebarMode;
  selectedIds: { [id: string]: boolean };
};

export class TransactionsMain extends PureComponent<
  {
    dispatch: Dispatch;

    transactions: Transaction[];
    splitsByTransaction: { [id: string]: Split[] };
    accountsMap: ReturnType<typeof accountsMapSelector>;
  },
  State
> {
  state: State = {
    selectedIds: {}
  };

  closeSidebar = () => this.setState({ sidebarMode: undefined });

  renderSidebar() {
    if (!this.state.sidebarMode) {
      return null;
    }
    const { splitsByTransaction } = this.props;
    switch (this.state.sidebarMode) {
      case SidebarMode.ADD_TRANSACTION:
        return (
          <div
            className={cx(
              pageCss.block,
              css.mainRight,
              css.addTransactionWrapper
            )}
          >
            <h1>Add a Transaction</h1>
            <ConnectedTransactionCreator
              onSave={async (transaction, splits) => {
                await this.props.dispatch(
                  addTransactions([transaction], splits)
                );
                this.closeSidebar();
              }}
            />
            <button
              onClick={this.closeSidebar}
              className={cx(css.addTransactionClose)}
            >
              Close
            </button>
          </div>
        );
      case SidebarMode.EDIT_TRANSACTION:
        const selectedTransactions = this.selectedTransactions();
        return (
          <div
            className={cx(
              pageCss.block,
              css.mainRight,
              css.addTransactionWrapper
            )}
          >
            <h1>
              Edit {selectedTransactions.length} Transaction
              {selectedTransactions.length > 1 ? "s" : ""}
            </h1>
            <TransactionsEditor
              transactions={selectedTransactions}
              splits={selectedTransactions.flatMap(
                tx => splitsByTransaction[tx.id] || []
              )}
              onSave={async mutations => {
                await this.props.dispatch(mutate(mutations));
                this.closeSidebar();
              }}
            />

            <button
              onClick={this.closeSidebar}
              className={cx(css.addTransactionClose)}
            >
              Close
            </button>
          </div>
        );
    }
    return null;
  }

  setSelectedIds = (selectedIds: { [id: string]: boolean }) => {
    this.setState({
      selectedIds
    });
  };

  private selectedTransactions() {
    return this.props.transactions.filter(tx => this.state.selectedIds[tx.id]);
  }

  render() {
    return (
      <div className={pageCss.pageWrapper}>
        <h1 className={pageCss.pageTitle}>Transactions</h1>
        <div className={css.main}>
          <div className={css.mainLeft}>
            <div className={cx(pageCss.block, css.actionsBlock)}>
              <button
                onClick={() =>
                  this.setState({ sidebarMode: SidebarMode.ADD_TRANSACTION })
                }
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
              >
                Add Transaction
              </button>

              <button
                onClick={() =>
                  this.setState({ sidebarMode: SidebarMode.EDIT_TRANSACTION })
                }
                className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              >
                Edit Transaction
              </button>
            </div>
            <div className={cx(pageCss.block, css.transactionsBlock)}>
              <Transactions
                transactions={this.props.transactions}
                accountsMap={this.props.accountsMap}
                splitsByTransaction={this.props.splitsByTransaction}
                setSelectedIds={this.setSelectedIds}
                selectedIds={this.state.selectedIds}
              />
            </div>
          </div>
          {this.renderSidebar()}
        </div>
      </div>
    );
  }
}

export const ConnectedTransactionsMain = connect((state: AppState) => ({
  transactions: reverseSortedTransactionsSelector(state),
  accountsMap: accountsMapSelector(state),
  splitsByTransaction: splitsByTransactionSelector(state)
}))(TransactionsMain);
