import {
  ImportedEntry,
  ExternalAccount,
  Transaction,
  Split
} from "../../model/bookkeeping";
import { UUID } from "../../lib/core/uuid";

export const IMPORTER_NS = "IMPORTER";

export type Entry = SettledEntry | CandidateEntry;

export interface SettledEntry {
  entry: ImportedEntry;
  accountExternalId: string;
  autoCategorizeP?: number;
}

export type CandidateEntry = SettledEntry & {
  candidateSplitId: UUID;
  candidateTransactionId: UUID;
};

export function entryIsSaved(entry: Entry): boolean {
  return !!entry.entry.splitId;
}

export function entryIsCandidate(entry: Entry): entry is CandidateEntry {
  return !!(entry as CandidateEntry).candidateSplitId;
}

export interface ExternalAccountData {
  account: ExternalAccount;
  entryIds: UUID[];
  balancingTransaction?: UUID;
  page?: number;
}

export interface CandidateTransaction {
  transaction: Transaction;
  splits: Split[];
}

export interface ImporterState {
  ready?: boolean;

  externalAccountsData: { [externalId: string]: ExternalAccountData };
  candidateTransactions: {
    [id: string]: CandidateTransaction;
  };
  entries: { [id: string]: Entry };
  selectedEntries: { [id: string]: boolean };
  cursorIndex: number;
  cursorAccount?: string;
  showImportedEntries?: boolean;

  csv?: string[][];
}
