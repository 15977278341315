import { Transaction, Split, ExternalAccount } from "../../model/bookkeeping";
import { unscaleValue, sum } from "../../model/scaled_value";
import { Dispatch, AppState } from "../store";
import { UUID } from "../../lib/core/uuid";
import * as ActionCreators from "./action_creators";
import { Account } from "../../model/bookkeeping";
import { getPersistence } from "../../persistence/factory";
import { accountsMapSelector } from "./selectors";
import { EXTERNAL_ACCOUNT_IDS } from "./tags";
import { batch } from "react-redux";
import { TransactionMutations } from "./types";

export function addTransactions(transactions: Transaction[], splits: Split[]) {
  if (unscaleValue(sum(...splits.map(split => split.valueScaled))) !== 0) {
    throw new Error("Splits don't balance!");
  }

  return async (dispatch: Dispatch) => {
    await getPersistence().addTransactions(transactions, splits);
    dispatch(ActionCreators.addTransactionsAndSplits(transactions, splits));
  };
}

export function loadData() {
  return async (dispatch: Dispatch) => {
    const {
      accounts,
      transactions,
      splits
    } = await getPersistence().getAccountData();
    batch(() => {
      dispatch(ActionCreators.addAccounts(accounts));
      dispatch(ActionCreators.addTransactionsAndSplits(transactions, splits));
      dispatch(ActionCreators.accountsReady());
    });
  };
}

export function addAccount(account: Account) {
  return async (dispatch: Dispatch) => {
    await getPersistence().addAccounts([account]);
    dispatch(ActionCreators.addAccounts([account]));
  };
}

export function addAccounts(accounts: Account[]) {
  return async (dispatch: Dispatch) => {
    await getPersistence().addAccounts(accounts);
    dispatch(ActionCreators.addAccounts(accounts));
  };
}

export function setAccountTag(accountId: UUID, tag: string, value: string) {
  return async (dispatch: Dispatch) => {
    await getPersistence().updateAccountTags(accountId, { [tag]: value });
    dispatch(ActionCreators.setAccountTag(accountId, tag, value));
  };
}

export function setImportToAccount(
  externalAccount: ExternalAccount,
  accountId: UUID
) {
  function appendToList(orig: string, item: string) {
    if (!orig) {
      return item;
    }
    const existing = orig.split(",");
    if (existing.includes(item)) {
      return orig;
    }
    return orig + "," + item;
  }

  return async (dispatch: Dispatch, getState: () => AppState) => {
    const oldValue = accountsMapSelector(getState()).get(accountId)!.tags[
      EXTERNAL_ACCOUNT_IDS
    ];
    const newTagValue = appendToList(oldValue, externalAccount.externalId);

    if (oldValue !== newTagValue) {
      await dispatch(
        setAccountTag(accountId, EXTERNAL_ACCOUNT_IDS, newTagValue)
      );
    }
  };
}

export function mutate(mutations: TransactionMutations) {
  return async (dispatch: Dispatch) => {
    await getPersistence().updateTransactionsAndSplits(mutations);
    await dispatch(ActionCreators.updateTransactionsAndSplits(mutations));
  };
}
