import React, { PureComponent, ComponentProps } from "react";
import ReactModal from "react-modal";

import css from "./modal.module.css";

export class Modal extends PureComponent<ComponentProps<typeof ReactModal>> {
  render() {
    return (
      <ReactModal overlayClassName={css.overlay} {...this.props}>
        {this.props.children}
      </ReactModal>
    );
  }
}
