import React, { PureComponent } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import {
  selectedAccountsBack,
  selectedAccountsForward,
  setFilter,
  clearFilter
} from "data/ledgers/actions";
import { AppState } from "data/store";
import {
  canGoForwardSelector,
  canGoBackSelector,
  filtersSelector
} from "data/ledgers/selectors";
import { ReactComponent as FilterIcon } from "../../assets/font-awesome-solid/filter.svg";
import { ReactComponent as LeftIcon } from "../../assets/font-awesome-solid/caret-square-left.svg";
import { ReactComponent as RightIcon } from "../../assets/font-awesome-solid/caret-square-right.svg";
import { ReactComponent as CloseIcon } from "../../assets/font-awesome-solid/times-circle.svg";
import { ReactComponent as AddIcon } from "../../assets/font-awesome-solid/plus-circle.svg";
import { DateRangePicker } from "components/common/date_range";
import { ConnectedMultiAccountSelector } from "components/common/multi_account_selector";
import { defaultDateRange } from "lib/datetime/date_range";

import css from "./top_bar.module.css";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import { removeFilter } from "data/ledgers/action_creators";

class NavigationWidget extends PureComponent<{
  onBack?: () => void;
  onForward?: () => void;
  className?: string;
}> {
  render() {
    return (
      <div className={this.props.className}>
        <button
          className={cx(buttonCss.styleless, css.navButton)}
          disabled={!this.props.onBack}
          onClick={this.props.onBack}
        >
          <LeftIcon width={20} height={20} />
        </button>
        <button
          className={cx(buttonCss.styleless, css.navButton)}
          disabled={!this.props.onForward}
          onClick={this.props.onForward}
        >
          <RightIcon width={20} height={20} />
        </button>
      </div>
    );
  }
}

export class TopBar extends PureComponent<{
  canGoBack: boolean;
  canGoForward: boolean;
  selectedAccountsBack: typeof selectedAccountsBack;
  selectedAccountsForward: typeof selectedAccountsForward;
  setFilter: typeof setFilter;
  removeFilter: typeof removeFilter;
  clearFilter: typeof clearFilter;
  filters: ReturnType<typeof filtersSelector>;
}> {
  toggle = () => {
    const { filters, setFilter, clearFilter } = this.props;
    if (filters && filters.length > 0) {
      clearFilter();
    } else {
      setFilter(0, {});
    }
  };

  addClause = () => {
    const { filters, setFilter } = this.props;
    setFilter(filters ? filters.length : 0, {});
  };

  render() {
    const { filters, removeFilter } = this.props;
    return (
      <div className={cx(css.wrapper, pageCss.block)}>
        <div className={css.container}>
          <NavigationWidget
            className={css.left}
            onBack={
              this.props.canGoBack ? this.props.selectedAccountsBack : undefined
            }
            onForward={
              this.props.canGoForward
                ? this.props.selectedAccountsForward
                : undefined
            }
          />
          <div className={css.right}>
            <button
              className={cx(buttonCss.styleless, css.filterButton)}
              onClick={this.toggle}
              aria-expanded={filters && filters.length > 0}
            >
              <FilterIcon width={14} height={14} />
            </button>
          </div>
        </div>
        {filters && filters.length > 0 && (
          <ul className={css.filterList}>
            {filters.map((filter, i) => (
              <li key={i}>
                <div>
                  <button
                    className={cx(css.deleteClauseButton, buttonCss.styleless)}
                    onClick={() => removeFilter(i)}
                  >
                    <CloseIcon width={16} height={16} />
                  </button>
                </div>
                <ul>
                  <li>
                    Date Range:{" "}
                    <label>
                      <input
                        type="radio"
                        name={`filter_date_range_${i}`}
                        checked={!filter.dateRange}
                        onChange={() =>
                          this.props.setFilter(i, {
                            dateRange: undefined
                          })
                        }
                      />{" "}
                      Any
                      <label>
                        <input
                          type="radio"
                          name={`filter_date_range_${i}`}
                          checked={!!filter.dateRange}
                          onChange={() =>
                            this.props.setFilter(i, {
                              dateRange: defaultDateRange()
                            })
                          }
                        />{" "}
                        Selected range
                      </label>
                      {filter.dateRange && (
                        <DateRangePicker
                          range={filter.dateRange}
                          onChange={v =>
                            this.props.setFilter(i, { dateRange: v })
                          }
                        />
                      )}
                    </label>
                  </li>
                  <li>
                    <label>
                      Memo{" "}
                      <input
                        type="text"
                        value={filter.memo || ""}
                        onChange={e =>
                          this.props.setFilter(i, {
                            memo: e.currentTarget.value
                          })
                        }
                      />
                    </label>
                  </li>
                  <li>
                    Accounts involved{" "}
                    <ConnectedMultiAccountSelector
                      selectedAccounts={filter.accountsInvolved || []}
                      onChange={accountsInvolved =>
                        this.props.setFilter(i, { accountsInvolved })
                      }
                    />
                  </li>
                </ul>
                <div className={css.addClauseCell}>
                  {i === filters.length - 1 ? (
                    <button
                      className={cx(buttonCss.styleless, css.addClauseButton)}
                      onClick={this.addClause}
                    >
                      <AddIcon width={16} height={16} />
                    </button>
                  ) : (
                    <span>-OR-</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export const ConnectedTopBar = connect(
  (state: AppState) => ({
    canGoBack: canGoBackSelector(state),
    canGoForward: canGoForwardSelector(state),
    filters: filtersSelector(state)
  }),
  {
    selectedAccountsBack,
    selectedAccountsForward,
    setFilter,
    clearFilter,
    removeFilter
  }
)(TopBar);
