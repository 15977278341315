import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./data/store";
import { loadData } from "./data/accounts/actions";
import { getPersistence } from "./persistence/factory";
import { runOobe } from "./data/oobe/actions";
import ReactModal from "react-modal";
import { AUTH_NS } from "data/auth/types";

getPersistence().registerNewAccountHandler(() => store.dispatch(runOobe()));

async function authAndFetch() {
  const mod = await import("auth/client");
  mod.addAuthChangeHandler(loggedIn => loggedIn && store.dispatch(loadData()));
  mod.initAuth(store);
}

if (store.getState()[AUTH_NS].requireLogin) {
  authAndFetch();
} else {
  // Kickoff initial data fetch
  store.dispatch(loadData());
}

const appNode = document.getElementById("root");
ReactModal.setAppElement(appNode!);
ReactDOM.render(<App />, appNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
